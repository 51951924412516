import {Route, Switch} from 'react-router-dom/cjs/react-router-dom.min';
import './App.css';
import Auth from './Common/Auth/Auth';
import {useSelector, useDispatch} from 'react-redux';
import Cars from './Components/Cars/Cars';
import {useEffect, useState} from 'react';
import axios from 'axios';
import {usersActions} from './store/user';
import Header from './Common/Header/Header';
import Users from './Components/Users/Users';
import CarsAdmin from './Components/Cars/CarsAdmin';
import UsersAdmin from './Components/Users/UsersAdmin';
import Container from './Components/Container';
import NotificationsAdmin from './Components/Notifications/NotificationsAdmin';
import {Button, Modal} from 'react-bootstrap';
import Notifications from './Components/Notifications/Notifications';
import Calculator from './Components/Calculator/Calculator';
import UsersCalculator from './Components/Calculator/UsersCalculator';
import Invoice from "./Components/Invoice/Invoice";
import Links from './Components/Links/Links';
import Sidebar from './Common/Sidebar';


function App() {
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const [isOpen, setIsOpen] = useState(false);
    const role = useSelector((state) => state.user.role);
    const dispatch = useDispatch();
    const [showNotifications, setShowNotifications] = useState(false);
    const [notification, setNotification] = useState({
        title: "",
        body: "",
        total: 0
    });
    const [active, setActive] = useState(0);
    const [user, setUser] = useState("");

    useEffect(() => {
        axios('/api/users')
            .then((res) => {
                dispatch(usersActions.changeIsLoggedIn(true));
                dispatch(usersActions.setLoggedInHandler(res.data.data.role));
                setUser(res.data.data.initials)
            })
            .catch((err) => {
                dispatch(usersActions.changeIsLoggedIn(false))
            })
    }, [])

    useEffect(() => {
        if (role === "user") {
            axios.get('/api/notifications/active')
                .then((res) => {
                    const data = res.data.data;
                    setNotification({
                        body: data,
                        title: data,
                        total: data.length,
                    })
                    if (data.length > 0) setShowNotifications(true);

                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [role])

    return (
        <div className="App">
            {isLoggedIn === true && <Header role={role} setIsOpen={setIsOpen} isOpen={isOpen} user={user}/>}
            {isLoggedIn === true && <Sidebar role={role} setIsOpen={setIsOpen} isOpen={isOpen}/>}
            <main>
                {isLoggedIn === false && <Auth/>}
                {isLoggedIn === true && (
                    <div className='page-main-content'>
                        {role === "user" && <>
                            <Switch>
                                <Route path='/' exact>
                                    <Cars/>
                                </Route>
                                <Route path='/users'>
                                    <Users/>
                                </Route>
                                <Route path='/calculator'>
                                    <UsersCalculator/>
                                </Route>
                                <Route path='/container-tracking' exact>
                                    <Container/>
                                </Route>
                                <Route path='/notifications'>
                                    <Notifications/>
                                </Route>
                                <Route path='/invoices'>
                                    <Invoice role={"user"}/>
                                </Route>
                            </Switch>
                            <Modal show={showNotifications} onHide={() => setShowNotifications(false)} size='md'>
                                <Modal.Header><h3>{notification.title[active] && notification.title[active].title}</h3>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>{notification.body[active] && notification.body[active].body}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    {
                                        notification.total - 1 > active ? (
                                            <Button variant='dark' onClick={() => {
                                                setActive((prev) => prev + 1)
                                            }}>Next</Button>
                                        ) : (
                                            <Button variant='dark' onClick={() => {
                                                setShowNotifications(false)
                                            }}>Close</Button>
                                        )
                                    }
                                </Modal.Footer>
                            </Modal>
                        </>}
                        {
                            role === "admin" && <Switch>
                                <Route path='/' exact>
                                    <CarsAdmin/>
                                </Route>
                                <Route path='/users' exact>
                                    <UsersAdmin/>
                                </Route>
                                <Route path='/calculator' exact>
                                    <Calculator/>
                                </Route>
                                <Route path='/container-tracking' exact>
                                    <Container/>
                                </Route>
                                <Route path='/notifications'>
                                    <NotificationsAdmin/>
                                </Route>
                                <Route path='/invoices'>
                                    <Invoice/>
                                </Route>
                                <Route path='/links'>
                                    <Links/>
                                </Route>
                            </Switch>
                        }
                    </div>
                )}
            </main>
        </div>
    );
}

export default App;
