import {Modal} from "react-bootstrap";
import {useState} from "react";
import axios from "axios";

const CreateInvoiceModal = ({show, setShow, data, cars, setCar}) => {
    const [originalInvoice, setOriginalInvoice] = useState(null);
    const [generatedInvoice, setGeneratedInvoice, setPortImages] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);

    const handleOriginalInvoice = (event) => {
        setOriginalInvoice(event.target.files);
    };

    const handleGeneratedInvoice = (event) => {
        setGeneratedInvoice(event.target.files);
    };

    const uploadOriginalInvoice = () => {
        const formData = new FormData();
        formData.append('invoice', originalInvoice[0]);
        formData.append('type', 'original');
        formData.append('vin', data.vin);
        formData.append('id_number', data.linked_user);
        formData.append('mark', data.mark);
        formData.append('model', data.model);
        formData.append('payed', false);
        setLoading(true)


        // Adjust the URL to match your Express server route for handling file uploads
        axios.post('/api/cars/invoice', formData)
            .then((response) => {
                alert("Invoice uploaded successfully");
                const invoice = response.data.url;
                const d = cars.map((c) => {
                    if (c.vin === data.vin) {
                        return { ...c, invoice_original: invoice }
                    } else {
                        return { ...c }
                    }
                });
                setCar([...d]);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                alert("Something went wrong");
            });
    };

    const uploadGeneratedInvoice = () => {
        const formData = new FormData();
        formData.append('invoice', generatedInvoice[0]);
        formData.append('type', 'generated');
        formData.append('vin', data.vin);
        formData.append('id_number', data.linked_user);
        formData.append('mark', data.mark);
        formData.append('model', data.model);
        formData.append('payed', false);
        setLoading1(true)


        // Adjust the URL to match your Express server route for handling file uploads
        axios.post('/api/cars/invoice', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                alert("Images uploaded successfully");
                const invoice = response.data.url;
                const d = cars.map((c) => {
                    if (c.vin === data.vin) {
                        return { ...c, invoice_generated: invoice }
                    } else {
                        return { ...c }
                    }
                })
                setCar([...d]);
                setLoading1(false);
            })
            .catch((error) => {
                alert("Something went wrong")
                setLoading1(false)
            });
    }

    return(
        <Modal show={show} onHide={() => setShow(false)} centered size="md">
            <Modal.Header>
                <h3>Upload Invoices</h3>
            </Modal.Header>
            <Modal.Body>
                <h4>Original invoice</h4>
                <div style={{marginBottom: "20px"}}>
                    <input type="file" style={{width: "250px"}} onChange={handleOriginalInvoice} />
                    <button onClick={uploadOriginalInvoice} disabled={loading ? true : false}>Upload</button>
                    {loading && <span style={{marginLeft: "8px"}}>Uploading...</span>}
                </div>
                <a target={"_blank"} href={data.invoice_original}>{data.invoice_original}</a>

                <h4 style={{ marginTop: "45px" }}>Generated invoice</h4>
                <div style={{marginBottom: "20px"}}>
                    <input type="file" multiple style={{width: "250px"}} onChange={handleGeneratedInvoice} />
                    {loading && <span>Uploading...</span>}
                    <button onClick={uploadGeneratedInvoice} disabled={loading ? true : false}>Upload</button>
                    {loading1 && <span style={{marginLeft: "8px"}}>Uploading...</span>}
                </div>
                <a target={"_blank"} href={data.invoice_generated}>{data.invoice_generated}</a>
            </Modal.Body>
        </Modal>
    )
}

export default CreateInvoiceModal