import { Button, Modal } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import axios from 'axios';
import './Header.css'
import { useState } from "react";
import {FaBars} from "react-icons/fa"

const Header = ({ user, role, setIsOpen, isOpen }) => {
    const [editUserModal, setEditUserModal] = useState(false);
    const [formData, setFormData] = useState({
        name: "", surname: "", email: "", phone: ""
    });
    const [notification, setNotification] = useState({
        message: "",
        body: "",
        show: false
    });
    const [active, setActive] = useState({});

    const logout = async () => {
        try {
            const response = await axios.post('/api/users/logout');
            window.location.href = "/";
        } catch (err) {
            console.log(err)
        }
    }

    const handlers = {
        async saveUser(e) {
            e.preventDefault();
            try {
                const response = axios.put(`/api/users/${active.ID_number}`, formData)
                setEditUserModal(false);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.body,
                    show: true
                })
            }
        }
    }

    return (
        <>
            <header className="header-container">
                <div className="header-content">
                    <Link to="/" className="header-logo">Sorex</Link>
                    <nav className="navbar-container">
                        <ul className="navbar-content">
                            <li><Link to="/">Cars</Link></li>
                            <li><Link to="/calculator">Calculator</Link></li>
                            <li><Link to="/users">Users</Link></li>
                            <li><Link to="/notifications">Notifications</Link></li>
                            <li><Link to={"/invoices"}>Invoices</Link></li>
                            {role === "admin" && <li><Link to={"/links"}>Links</Link></li>}
                            <li><Link to="container-tracking">Search container</Link></li>
                        </ul>
                    </nav>
                    <div className="header-actions">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <p style={{ cursor: "pointer" }} onClick={() => {
                                setEditUserModal(true)
                                axios('/api/users')
                                    .then((res) => {
                                        const user = res.data.data;
                                        setActive({ ...user });
                                        setFormData({
                                            name: user.name, surname: user.surname, email: user.email, phone: user.phone, ID_number: user.ID_number, address: user.address,
                                            country: user.country, city: user.city, initials: user.initials, country_code: user.country_code,
                                            username: user.username, cars_id: user.cars_id, balance: user.balance, debt: user.debt
                                        });
                                    })
                                    .catch((err) => {
                                        alert("Something went wrong")
                                    })
                            }}>{user.toUpperCase()}</p>
                            <img src="/static/icons/down.png" style={{width: "12px"}} />
                        </div>
                        <Button variant="dark" onClick={logout}>Log out</Button>
                    </div>
                    <button className="bar-icon" onClick={() => setIsOpen(!isOpen)}><FaBars/></button>
                </div>
            </header>
            <Modal show={editUserModal} onHide={() => setEditUserModal(false)} size="lg" centered>
                <Modal.Header>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                        <h3>Edit user</h3>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <p style={{marginBottom: "0px", marginRight: "10px"}}><strong>Balance: </strong><span>{formData.balance === 0 ? "0.00" : formData.balance}</span></p>
                            <p style={{marginBottom: "0px"}}><strong>Debt: </strong><span>{formData.debt === 0 ? "0.00" : formData.debt}</span></p>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form className="" onSubmit={handlers.saveUser}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" value={formData.name} required className="form-control" placeholder="Enter name" onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Surname</label>
                                            <input type="text" value={formData.surname} required className="form-control" placeholder="Enter surname" onChange={(e) => setFormData((prev) => ({ ...prev, surname: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" value={formData.email} required className="form-control" placeholder="Enter email" onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <input type="text" value={formData.phone} required className="form-control" placeholder="Enter phone" onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>ID number</label>
                                            <input type="text" value={formData.ID_number} required className="form-control" placeholder="Enter ID number" onChange={(e) => setFormData((prev) => ({ ...prev, ID_number: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input type="text" value={formData.address} required className="form-control" placeholder="Enter address" onChange={(e) => setFormData((prev) => ({ ...prev, address: e.target.value }))} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Country</label>
                                            <input type="text" value={formData.country} required className="form-control" placeholder="Enter country" onChange={(e) => setFormData((prev) => ({ ...prev, country: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>City</label>
                                            <input type="text" value={formData.city} required className="form-control" placeholder="Enter city" onChange={(e) => setFormData((prev) => ({ ...prev, city: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>password</label>
                                            <input type="password" value={formData.password} className="form-control" placeholder="Enter password" onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Country code</label>
                                            <input type="text" value={formData.country_code} required className="form-control" placeholder="Enter country code" onChange={(e) => setFormData((prev) => ({ ...prev, country_code: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input type="text" value={formData.username} required className="form-control" placeholder="Enter username" onChange={(e) => setFormData((prev) => ({ ...prev, username: e.target.value }))} />
                                        </div>
                                    </div>
                                </div>
                                <Button variant="dark" type="submit" style={{ width: "100%" }}>Save</Button>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={notification.show} onHide={() => {
                setNotification((prev) => ({ ...prev, show: false }))
            }} >
                <Modal.Header>
                    <h3>{notification.message}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h4>{notification.body}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {
                        setNotification((prev) => ({ ...prev, show: false }))
                    }}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Header