import axios from "axios";
import { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";

const UsersAdmin = () => {
    const [total, setTotal] = useState(0);
    const [pageChanged, setPageChanged] = useState(1);
    const [users, setUsers] = useState([]);
    const [notification, setNotification] = useState({
        message: "",
        body: "",
        show: false
    });
    const [userStatus, setUserStatus] = useState("natural");
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [active, setActive] = useState({});
    const [addUsersModal, setAddUserModal] = useState(false);
    const [formData, setFormData] = useState({
        name: "", surname: "", email: "", phone: ""
    });
    const [editUserModal, setEditUserModal] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [showRedirectLogin, setShowRedirectLogin] = useState(false);

    useEffect(() => {
        axios(`/api/admin/${userStatus === "natural" ? "users" : "legal"}?limit=40&page=${pageChanged}&keyword=${keyword}`)
            .then((res) => {
                const data = res.data.data;
                setUsers([...data]);
                setTotal(res.data.total)
            })
            .catch((err) => {
                setNotification({
                    message: "Server side error",
                    body: "Cars not loaded",
                    show: true
                })
            })
    }, [pageChanged, keyword, userStatus]);

    const handlers = {
        async saveUser(e) {
            e.preventDefault();
            try {
                const response = axios.put(`/api/users/${active.ID_number}`, formData)
                const data = users.map((u) => {
                    if (u.ID_number === active.ID_number) {
                        return {
                            ...u, name: formData.name, surname: formData.surname, email: formData.email, phone: formData.phone, ID_number: formData.ID_number, address: formData.address,
                            country: formData.country, city: formData.city, initials: formData.initials, country_code: formData.country_code,
                            active: formData.active, role: formData.role, username: formData.username, cars_id: formData.cars_id, invoice_id: formData.invoice_id
                        }
                    } else {
                        return { ...u }
                    }
                })
                setEditUserModal(false);

                setUsers([...data]);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.body,
                    show: true
                })
            }
        },
        async addUser(e) {
            e.preventDefault();
            try {
                const response = await axios.post(`/api/users`, {...formData, type: userStatus})
                const data = [{ ...formData }, ...users];
                setAddUserModal(false);
                setUsers([...data]);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.status,
                    body: data.message,
                    show: true
                })
            }
        },
        deleteUser(e) {
            e.preventDefault();
            try {
                const response = axios.delete(`/api/users/${active.ID_number}`);
                const data = users.filter((u) => u.ID_number != active.ID_number);
                setShowDeleteUser(false);
                setUsers([...data]);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.body,
                    show: true
                })
            }
        }
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
                <input type="search" style={{maxWidth: "300px"}} onKeyDown={(e) => {
                    if(e.keyCode === 13){
                        setKeyword(e.target.value)
                    }
                }} className="form-control" placeholder="Search by phone, email, ID number" />
                <div className={"users-type-switcher-container"}>
                    <button onClick={() => setUserStatus("natural")} className={userStatus === "natural" ? "active white" : "white"}>Natural person</button>
                    <button onClick={() => setUserStatus("legal")} className={userStatus === "legal" ? "active white" : "white"}>Legal person</button>
                </div>
                <Button onClick={() => {
                    setAddUserModal(true);
                    setFormData({
                        name: "", surname: "", email: "", phone: "", ID_number: "", address: "",
                        country: "", city: "", initials: "", country_code: "",
                        active: "", role: "", username: "", cars_id: "", invoice_id: ""
                    })
                }} variant='dark'>Add new</Button>
            </div>
            <Table responsive className="responsive-data-table">
                <thead>
                    <tr className="table-header">
                        <th>ID</th>
                        <th className="table-header-items">{userStatus === "natural" ? "Name" : "Company name"}</th>
                        {userStatus === "natural" && <th className="table-header-items">Surname</th>}
                        <th className="table-header-items">Email</th>
                        <th className="table-header-items">Role</th>
                        <th className="table-header-items">Username</th>
                        <th className="table-header-items">Country</th>
                        <th className="table-header-items">Phone</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((user) => {
                            return (
                                <tr key={user.ID_number}>
                                    <td style={{padding: "0 0 0 10px"}}>{user.id}</td>
                                    <td style={{padding: "0 0 0 10px"}} className={"user-table-rows"}>{userStatus === "natural" ? user.name : user.companyName}</td>
                                    {userStatus === "natural" && <td style={{padding: "0 0 0 10px"}}>{user.surname}</td>}
                                    <td style={{padding: "0 0 0 10px"}} className={"user-table-rows"}>{user.email}</td>
                                    <td style={{padding: "0 0 0 10px"}} className={"user-table-rows"}>{user.role}</td>
                                    <td style={{padding: "0 0 0 10px"}} className={"user-table-rows"}>{user.username}</td>
                                    <td style={{padding: "0 0 0 10px"}} className={"user-table-rows"}>{user.country}</td>
                                    <td style={{padding: "0 0 0 10px"}} className={"user-table-rows"}>{user.phone}</td>
                                    <td style={{padding: "0 0 0 10px"}} className="table-action-items user-table-rows">
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} src="https://usdelivery.ge/static/image/edit.svg" alt="edit" onClick={() => {
                                            setActive({ ...user });
                                            setFormData({
                                                name: user.name, surname: user.surname, email: user.email, phone: user.phone, ID_number: user.ID_number, address: user.address,
                                                country: user.country, city: user.city, initials: user.initials, country_code: user.country_code,
                                                active: user.active, role: user.role, username: user.username, cars_id: user.cars_id, invoice_id: user.invoice_id,
                                                debt: user.debt, balance: user.balance
                                            });
                                            setEditUserModal(true);
                                        }} />
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} src="https://cdn-icons-png.flaticon.com/512/484/484662.png" alt="edit" onClick={() => {
                                            setActive({ ...user });
                                            setShowDeleteUser(true);
                                        }} />
                                        <img className="actions-icon" title={`login as ${user.name} ${user.surname}`} style={{ marginLeft: "0px" }} src="/static/icons/login.png" alt="edit" onClick={() => {
                                            setActive({ ...user });
                                            setShowRedirectLogin(true)
                                        }} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        users.length === 0 && (
                            <tr>
                                <td colSpan="8">
                                    <div className="empty-records"><p>Records not found</p></div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <div className={"pagonation-content"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                    {'<<'}
                </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return
                                } else {
                                    return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                        setPageChanged(p + 1)
                                    }
                                    }>
                                    {p + 1}
                                </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                    {'>>'}
                </span>}
                </div>
                <p><strong>Total:</strong> {total}</p>
            </div>
            <Modal show={notification.show} onHide={() => {
                setNotification((prev) => ({ ...prev, show: false }))
            }} >
                <Modal.Header>
                    <h3>{notification.message}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h4>{notification.body}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {
                        setNotification((prev) => ({ ...prev, show: false }))
                    }}>Ok</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={editUserModal} onHide={() => setEditUserModal(false)} size="lg" centered>
                <Modal.Header>
                    <h3>Edit user</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form className="" onSubmit={handlers.saveUser}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" value={formData.name} required className="form-control" placeholder="Enter name" onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Surname</label>
                                            <input type="text" value={formData.surname} required className="form-control" placeholder="Enter surname" onChange={(e) => setFormData((prev) => ({ ...prev, surname: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" value={formData.email} required className="form-control" placeholder="Enter email" onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <input type="text" value={formData.phone} required className="form-control" placeholder="Enter phone" onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>ID number</label>
                                            <input type="text" value={formData.ID_number} required className="form-control" placeholder="Enter ID number" onChange={(e) => setFormData((prev) => ({ ...prev, ID_number: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input type="text" value={formData.address} required className="form-control" placeholder="Enter address" onChange={(e) => setFormData((prev) => ({ ...prev, address: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Country</label>
                                            <input type="text" value={formData.country} required className="form-control" placeholder="Enter country" onChange={(e) => setFormData((prev) => ({ ...prev, country: e.target.value }))} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>City</label>
                                            <input type="text" value={formData.city} required className="form-control" placeholder="Enter city" onChange={(e) => setFormData((prev) => ({ ...prev, city: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>password</label>
                                            <input type="text" value={formData.password} className="form-control" placeholder="Enter password" onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>role</label>
                                            <input type="text" value={formData.role} required className="form-control" placeholder="Enter role" onChange={(e) => setFormData((prev) => ({ ...prev, role: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input type="text" value={formData.username} required className="form-control" placeholder="Enter username" onChange={(e) => setFormData((prev) => ({ ...prev, username: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Debt</label>
                                            <input type="text" value={formData.debt} required className="form-control" placeholder="Enter debt" onChange={(e) => setFormData((prev) => ({ ...prev, debt: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Balance</label>
                                            <input type="text" value={formData.balance} required className="form-control" placeholder="Enter balance" onChange={(e) => setFormData((prev) => ({ ...prev, balance: e.target.value }))} />
                                        </div>
                                    </div>
                                </div>
                                <Button variant="dark" type="submit" style={{ width: "100%" }}>Save</Button>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={addUsersModal} onHide={() => setAddUserModal(false)} size="lg" centered>
                <Modal.Header>
                    <h3>Add new user</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={"registration-mode-switcher"}>
                                <button onClick={() => setUserStatus("natural")} className={userStatus === "natural" ? "active" : ""}>Natural person</button>
                                <button onClick={() => setUserStatus("legal")} className={userStatus === "legal" ? "active" : ""}>Legal person</button>
                            </div>
                            <form className="" onSubmit={handlers.addUser}>
                                <div className="row">
                                    <div className="col-md-6">
                                        {
                                            userStatus === "natural" && (
                                                <>
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input type="text" value={formData.name} required className="form-control" placeholder="Enter name" onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Surname</label>
                                                        <input type="text" value={formData.surname} required className="form-control" placeholder="Enter surname" onChange={(e) => setFormData((prev) => ({ ...prev, surname: e.target.value }))} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            userStatus === "legal" && (
                                                <>
                                                    <div className="form-group">
                                                        <label>Company name</label>
                                                        <input type="text" value={formData.name} required className="form-control" placeholder="Enter name" onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" value={formData.email} required className="form-control" placeholder="Enter email" onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <input type="text" value={formData.phone} required className="form-control" placeholder="Enter phone" onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>{userStatus === "natural" ? "ID number" : "Tax ID"}</label>
                                            <input type="text" value={formData.ID_number} required className="form-control" placeholder={userStatus === "natural" ? "Enter ID number" : "Enter tax ID"} onChange={(e) => setFormData((prev) => ({ ...prev, ID_number: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input type="text" value={formData.address} required className="form-control" placeholder="Enter address" onChange={(e) => setFormData((prev) => ({ ...prev, address: e.target.value }))} />
                                        </div>
                                        {userStatus === "legal" && <div className="form-group">
                                            <label>Username</label>
                                            <input type="text" value={formData.username} required
                                                   className="form-control" placeholder="Enter username"
                                                   onChange={(e) => setFormData((prev) => ({
                                                       ...prev,
                                                       username: e.target.value
                                                   }))}/>
                                        </div>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Country</label>
                                            <input type="text" value={formData.country} required className="form-control" placeholder="Enter country" onChange={(e) => setFormData((prev) => ({ ...prev, country: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>City</label>
                                            <input type="text" value={formData.city} required className="form-control" placeholder="Enter city" onChange={(e) => setFormData((prev) => ({ ...prev, city: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>password</label>
                                            <input type="password" value={formData.password} required className="form-control" placeholder="Enter password" onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))} />
                                        </div>
                                        <div className="form-group">
                                            <label>role</label>
                                            <select value={formData.role} className="form-control" required onChange={(e) => setFormData((prev) => ({ ...prev, role: e.target.value }))} >
                                                <option>Select user</option>
                                                <option value="user">User</option>
                                                <option value="admin">Admin</option>
                                            </select>
                                        </div>
                                        {userStatus === "natural" && <div className="form-group">
                                            <label>Username</label>
                                            <input type="text" value={formData.username} required
                                                   className="form-control" placeholder="Enter username"
                                                   onChange={(e) => setFormData((prev) => ({
                                                       ...prev,
                                                       username: e.target.value
                                                   }))}/>
                                        </div>}
                                    </div>
                                </div>
                                <Button variant="dark" type="submit" style={{ width: "100%" }}>Save</Button>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteUser} onHide={() => setShowDeleteUser(false)} size='md'>
                <Modal.Header>
                    <h3>Do you want to delete user?</h3>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button variant="danger" onClick={handlers.deleteUser}>Yes</Button>
                        <Button variant="dark" onClick={() => setShowDeleteUser(false)} style={{ marginLeft: "15px" }}>No</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showRedirectLogin} onHide={() => setShowRedirectLogin(false)} size='md'>
                <Modal.Header>
                    <h3>Do you want to login as {`${active.name} ${active.surname}`}?</h3>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button variant="danger" onClick={handlers.deleteUser}>Yes</Button>
                        <Button variant="dark" onClick={() => setShowRedirectLogin(false)} style={{ marginLeft: "15px" }}>No</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UsersAdmin
