import { useState, useEffect } from "react";
import axios from 'axios';

const UsersCalculator = () => {
    const [select, setSelect] = useState({
        auction: "",
        state: "",
        port: ""
    });
    const [value, setValue] = useState({
        states: [],
        ports: []
    })

    const [price, setPrice] = useState("");

    const handlers = {
        async getStates(e) {
            try {
                const response = await axios.get(`/api/calculator/load/states?auction=${e}`);
                const states = response.data.data;
                setValue((prev) => ({ ...prev, states: [...states], port: [] }));
                setSelect({
                    auction: e,
                    state: "",
                    port: ""
                })
            } catch (err) {
                alert("States not found")
            }
        },
        async getPorts(e) {
            try {
                const response = await axios.get(`/api/calculator/load/port?state=${e}&auction=${select.auction}`);
                const ports = response.data.data;
                setValue((prev) => ({ ...prev, ports: [...ports] }))
                setSelect((prev) => ({ ...prev, state: e, port: "" }))
            } catch (err) {
                alert("Ports not found")
            }
        },
        async getPrice(e) {
            try {
                const response = await axios.get(`/api/calculator/load/get-price?state=${select.state}&auction=${select.auction}&port=${e}`);
                setSelect((prev) => ({ ...prev, port: e }))
                let p = response.data.data[0];
                p = parseInt(p.price) + parseInt(p.fee);
                setPrice(p)
            } catch (err) {
                alert("Price not calculated")
            }
        }
    }

    return (
        <>
            {/* <div className="users-calculator-container row">
                <div className="col-md-4 mobile-m-t">
                    <label style={{ color: "white" }}>Auction</label>
                    <select value={select.auction} className="form-control" onChange={(e) => {
                        handlers.getStates(e.target.value)
                    }}>
                        <option>Select auction</option>
                        <option value="copart">Copart</option>
                        <option value="iaai">IAAI</option>
                    </select>
                </div>
                <div className="col-md-4 mobile-m-t">
                    <label style={{ color: "white" }}>State</label>
                    <select value={select.state} className="form-control" onChange={(e) => {
                        handlers.getPorts(e.target.value)
                    }}>
                        <option>Select state</option>
                        {
                            value.states.map((s) => {
                                return(
                                    <option value={s.state}>{s.state}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-md-4 mobile-m-t">
                    <label style={{ color: "white" }}>Port</label>
                    <select value={select.port} className="form-control" onChange={(e) => {
                        handlers.getPrice(e.target.value)
                    }}>
                        <option>Select port</option>
                        {
                            value.ports.map((s) => {
                                return(
                                    <option value={s.port}>{s.port}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="price-amount-content">
                <strong>Transportation amount:</strong>
                <p>${price}</p>
            </div> */}
            <div className="iframe-container">
                <iframe src="http://srl.ge/en/content/shipping-calculator" width={"100%"} id="myIframe" className="embedded-iframe" style={{ height: "calc(100vh + 200px)" }}></iframe>
            </div>
        </>
    )
}

export default UsersCalculator