import { Button } from 'react-bootstrap'
import './TableHeader.css'
import {useEffect, useState} from "react";
import axios from "axios";

const TableHeader = ({ main, linkedUsers, filter, search, action, keyword, withSelect, selectOnChange }) => {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        if(linkedUsers){
            axios('/api/admin/users?limit=500&page=1')
                .then((res) => {
                    const data = res.data.data;
                    setUsers([...data]);
                })
                .catch(() => {
                    alert("users not loaded")
                })
        }
    }, [])

    return (
        <div className="table-header-content">
            {search && (
                <>
                    <input type="text" onKeyUp={(e) => {
                        console.log(e.target.value)
                        if(e.keyCode === 13){
                            keyword(e.target.value)
                        }
                    }} placeholder='Search by keyword' style={{ maxWidth: "350px", marginBottom: "10px" }} className='form-control' />
                    {linkedUsers && <select className={"form-control"} onChange={(e) => filter(e.target.value)}
                             style={{maxWidth: "200px", marginBottom: "10px"}}>
                        <option>Linked user</option>
                        {users.map((d) => {
                            return (typeof d.companyName == "undefined" ? (<option key={d.ID_number}
                                                                                   value={d.id}>{`${d.id} - ${d.name} ${d.surname} - ${d.phone}`}</option>) : (

                                <option key={d.taxId}
                                        value={d.taxId}>{`${d.id} - ${d.companyName} - ${d.phone}`}</option>))
                        })}
                    </select>}
                    {main && <Button style={{marginBottom: "10px"}} onClick={() => action()} variant='info'>Add</Button>}
                    {
                        withSelect && (
                            <select className={"form-control"} style={{maxWidth: "150px", marginBottom: "10px"}} onChange={(e) => selectOnChange(e.target.value)}>
                                <option value={""}>Invoice status</option>
                                <option value={"payed"}>Payed</option>
                                <option value={"unpaid"}>Unpaid</option>
                            </select>
                        )
                    }
                </>
            )}
        </div>
    )
}

export default TableHeader