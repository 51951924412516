import axios from "axios"
import { useEffect, useState } from "react"
import { Modal, Table, Button } from "react-bootstrap"
import './Cars.css'
import TableHeader from "../../Common/TableHeader/TableHeader"
import AddCarsModal from "../AddCarsModal"
import ViewCarsModal from "../ViewCarsModal"
import EditCarsAdmin from "./EditCarsAdmin"
import image from './icons.svg'
import UploadImages from "./UploadImages"
import CreateInvoiceModal from "./CreateInvoiceModal";

const CarsAdmin = () => {
    const [total, setTotal] = useState(0);
    const [pageChanged, setPageChanged] = useState(1);
    const [cars, setCars] = useState([]);
    const [showViewModal, setShowViewModal] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [notification, setNotification] = useState({
        message: "",
        body: "",
        show: false
    });
    const [sort, setSort] = useState({ sort: "id", desc: "yes" });
    const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
    const [active, setActive] = useState({});
    const [addCarsModal, setAddCarsModal] = useState(false);
    const [formData, setFormData] = useState({
        vin: "", mark: "", model: "", year: "", color: "", title: "", auction: "", lot: "", is_sublot: false, has_key: false, insurance: false, linked_user: ""
    });
    const [editCarsModal, setEditCarsModal] = useState(false);
    const [showUploadImages, setShowUploadImages] = useState(false);
    const [userFilter, setUserFilter] = useState("");

    useEffect(() => {
        axios(`/api/cars?limit=10&page=${pageChanged}&keyword=${keyword}&user=${userFilter}&sort=${sort.sort}&desc=${sort.desc}`)
            .then((res) => {
                const data = res.data.data;
                setCars([...data]);
                setTotal(res.data.total)
            })
            .catch((err) => {
                setNotification({
                    message: "Server side error",
                    body: "Cars not loaded",
                    show: true
                })
            })
    }, [pageChanged, keyword, userFilter, sort]);

    const handlers = {
        async addNewCar(e) {
            e.preventDefault();
            try {
                const response = await axios.post('/api/cars', formData);
                setCars([{ ...formData }, ...cars]);
                setAddCarsModal(false);
            } catch (err) {
                const data = err.response.data;
                console.log(data.err.sqlMessage.slice(0, 15))
                setNotification({
                    message: data.description,
                    body: data.err.sqlMessage.slice(0, 15) === "Duplicate entry" ? "Car already exist" : data.message,
                    show: true
                })
            }
        },
        showAddCarsModal() {
            setAddCarsModal(true);
            setFormData({
                vin: "", mark: "", model: "", linked_user: "", year: "", document: "", comment: "", color: "", auction_city: "", state: "", title: "", auction: "", lot: "", is_sublot: false, has_key: false, insurance: false
            });
        },
        async editCar(e) {
            e.preventDefault();
            try {
                const response = await axios.put(`/api/cars/${active.vin}`, formData);
                const data = cars.map((c) => {
                    if (c.vin === active.vin) {
                        return { ...formData }
                    } else {
                        return { ...c }
                    }
                })
                setEditCarsModal(false)
                setCars([...data]);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.body,
                    show: true
                })
            }
        },
        async deleteCar(e) {
            e.preventDefault();
            try {
                const response = await axios.detele(`/api/cars/${active.vin}`, formData);
                const data = cars.filter((c) => c.vin !== active.vin);
                setCars([...data])
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.body,
                    show: true
                })
            }
        }
    }

    const setFilterHandler = (value) => {
        setUserFilter(value);
    }

    return (
        <>
            <TableHeader linkedUsers={true} keyword={setKeyword} filter={(e) => { setFilterHandler(e) }} search={true} main={true} action={handlers.showAddCarsModal} />
            <Table responsive className="responsive-data-table">
                <thead>
                    <tr className="table-header">
                        <td className="table-header-items">Owner</td>
                        <td className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "year", desc: prev.desc === "yes" ? "no" : "yes" }))}>Year</td>
                        <td className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "mark", desc: prev.desc === "yes" ? "no" : "yes" }))}>Mark</td>
                        <td className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "model", desc: prev.desc === "yes" ? "no" : "yes" }))}>Model</td>
                        <td className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "vin", desc: prev.desc === "yes" ? "no" : "yes" }))}>VIN</td>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "lot", desc: prev.desc === "yes" ? "no" : "yes" }))}>Lot</th>
                        <td className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "auction", desc: prev.desc === "yes" ? "no" : "yes" }))}>Auction</td>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "auction_city", desc: prev.desc === "yes" ? "no" : "yes" }))}>City</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "port", desc: prev.desc === "yes" ? "no" : "yes" }))}>Port</th>
                        <td className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "title", desc: prev.desc === "yes" ? "no" : "yes" }))}>Title</td>
                        <th className="table-header-items">Container #</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "price", desc: prev.desc === "yes" ? "no" : "yes" }))}>Price</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "receiver", desc: prev.desc === "yes" ? "no" : "yes" }))}>Receiver</th>
                        <td className="table-header-items"></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        cars.map((car) => {
                            const arrivalDate = +new Date(car.arrival_date);
                            const dateNow = +new Date();
                            const dateResult = arrivalDate - dateNow;
                            return (
                                <tr className={car.payed ? "payed-item table-rows" : 'table-rows'} key={car.vin} title={`${car.receiver}`} >
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.linked_user}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.year}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{(typeof car.mark == "undefined" || car.mark === "") ? "Empty" : car.mark}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.model}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}><span>{car.vin.slice(0, -6)}</span><span style={{ color: "red", fontWeight: "bold" }}>{car.vin.slice(-6)}</span></td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.lot}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.auction}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.auction_city}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{(typeof car.port == "undefined" || car.port == null || car.port === "") ? "Empty" : car.port}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.title === "YES" ? <img style={{ width: "20px" }} src="/static/icons/check.png" alt="Yes" /> : <img style={{ width: "20px" }} src="/static/icons/cross.png" alt="No" />}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{(typeof car.container_number == "undefined" || car.container_number === "") ? "Empty" : car.container_number}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>${car.price}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{(typeof car.receiver == "undefined" || car.receiver == null || car.receiver === "") ? "Empty" : car.receiver}</td>
                                    <td style={{ width: "70px" }} className={`table-action-items ${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed == false && car.arrival_date != null) ? "unpaid-item" : ""}`}>
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} src={"/static/icons/invoice.png"} alt="view" onClick={() => {
                                            setShowCreateInvoiceModal(true);
                                            setActive({ ...car });
                                        }} />
                                        <img className="actions-icon" style={{ margin: "0px 0px" }} src={image} alt="view" onClick={() => {
                                            setShowUploadImages(true);
                                            setActive({ ...car });
                                        }} />
                                        <img className="actions-icon" src="https://usdelivery.ge/static/image/edit.svg" alt="edit" onClick={() => {
                                            setActive({ ...car });
                                            setFormData({
                                                vin: car.vin, mark: car.mark, model: car.model, linked_user: car.linked_user, year: car.year, comment: car.comment, color: car.color,
                                                auction_city: car.auction_city, status: car.status, warehouse_receive_date: car.warehouse_receive_date, state: car.state, title: car.title, auction: car.auction, lot: car.lot, is_sublot: car.is_sublot, has_key: car.has_key,
                                                insurance: car.insurance, load_state: car.load_state, payment_date: car.payment_date, destination_port: car.destination_port, container_number: car.container_number,
                                                search_by_container: car.search_by_container, sea_line: car.sea_line, arrival_date: car.arrival_date, tracking_url: car.tracking_url, fine: car.fine, document: car.document,
                                                ev_hybrid: car.ev_hybrid, invoice_original: car.invoice_original, invoice_generated: car.invoice_generated, transportation_payment_date: car.transportation_payment_date,
                                                receive_status: car.receive_status, loading_date: car.loading_date, port: car.port, buyer: car.buyer, receiver: car.receiver, warehouse_images: car.warehouse_images, payed: car.payed, price: car.price
                                            });
                                            setEditCarsModal(true)
                                        }} />

                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        cars.length === 0 && (
                            <tr>
                                <td colSpan="13">
                                    <div className="empty-records"><p>Records not found</p></div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <div className={"pagonation-content"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                        {'<<'}
                    </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return
                                } else {
                                    return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                        setPageChanged(p + 1)
                                    }
                                    }>
                                        {p + 1}
                                    </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                        {'>>'}
                    </span>}
                </div>
                <p><strong>Total:</strong> {total}</p>
            </div>
            <Modal show={notification.show} onHide={() => {
                setNotification((prev) => ({ ...prev, show: false }))
            }} >
                <Modal.Header>
                    <h3>{notification.message}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h4>{notification.body}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {
                        setNotification((prev) => ({ ...prev, show: false }))
                    }}>Ok</Button>
                </Modal.Footer>
            </Modal>
            <AddCarsModal submitFormHandler={handlers.addNewCar} data={formData} setData={setFormData} actionType={"add"} hide={() => setAddCarsModal(false)} show={addCarsModal} />
            <EditCarsAdmin submitFormHandler={handlers.editCar} data={formData} setData={setFormData} cars={cars} setCars={setCars} actionType={"edit"} hide={setEditCarsModal} show={editCarsModal} />
            <ViewCarsModal data={active} show={showViewModal} setShowModal={setShowViewModal} />
            <UploadImages show={showUploadImages} data={active} cars={cars} setData={setActive} setCar={setCars} setShow={setShowUploadImages} vin={active.vin} />
            <CreateInvoiceModal show={showCreateInvoiceModal} setShow={setShowCreateInvoiceModal} data={active} cars={cars} setCar={setCars} />
        </>
    )
}

export default CarsAdmin