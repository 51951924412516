import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";


const Links = () => {
    const [formData, setFormData] = useState({
        title: "", link: ""
    });
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [loader, setLoader] = useState(true);
    const [total, setTotal] = useState(0);
    const [pageChanged, setPageChanged] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [links, setLinks] = useState([]);

    useEffect(() => {
        axios(`/api/links?limit=20&page=${pageChanged}&keyword=${keyword}`)
            .then((res) => {
                const data = res.data.data;
                setLoader(false);
                setTotal(res.data.total)
                setLinks([...data]);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [keyword, pageChanged])

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
                <input type="search" style={{ maxWidth: "300px" }} className="form-control" placeholder="Search by Title" onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        setKeyword(e.target.value);
                    }
                }} />
                <Button variant='dark' onClick={() => {
                    setShowAddItemModal(true);
                    setFormData({
                        title: "", link: ""
                    });
                }}>Add</Button>
            </div>
            <Table responsive className="responsive-data-table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>URL</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        links.map((i) => {
                            return (
                                <tr key={i.id}>
                                    <td>{i.title}</td>
                                    <td><a href={i.link} target="_blank">{i.link}</a></td>
                                </tr>
                            )
                        })
                    }
                    {
                        links.length === 0 && (
                            <tr>
                                <td colSpan="8">
                                    <div className="empty-records"><p>{loader ? "Loading..." : "Records not found"}</p></div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <div className={"pagonation-content"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                        {'<<'}
                    </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return
                                } else {
                                    return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                        setPageChanged(p + 1)
                                    }
                                    }>
                                        {p + 1}
                                    </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                        {'>>'}
                    </span>}
                </div>
                <p><strong>Total:</strong> {total}</p>
            </div>
            <Modal show={showAddItemModal} onHide={() => setShowAddItemModal(false)} size="md">
                <Modal.Header><h5>Add new link</h5></Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                            axios.post(`/api/links`, formData)
                               .then(() => {
                                    setLinks((prev) => ([{...formData}, ...prev]));
                                    setShowAddItemModal(false);
                                })
                                .catch((err) => {
                                    console.log(err)
                                }) 
                        }}>
                        <div className="form-group">
                            <label>Title</label>
                            <input type="text" className="form-control" onChange={(e) => setFormData((prev) => ({...prev, title: e.target.value}))} value={formData.title}/>
                        </div>
                        <div className="form-group">
                            <label>Link</label>
                            <input type="text" className="form-control" onChange={(e) => setFormData((prev) => ({...prev, link: e.target.value}))} value={formData.link}/>
                        </div>
                        <Button type="submit" variant="dark" style={{width: "100%"}}>Add</Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Links