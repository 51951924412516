import { Modal, } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const ViewCarsModal = ({ data, show, setShowModal }) => {
    function copyToClipboard(text) {
        navigator.clipboard.writeText(text)
            .then(function () {
                console.log('Copied to clipboard');
            })
            .catch(function (err) {
                console.error('Unable to copy', err);
            });
    }

    return (
        <Modal show={show} onHide={() => setShowModal(false)} size="xl" centered>
            <Modal.Header>
                <h3>View car data | Receiver: {data.receiver}</h3>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4">
                        <div className="view-cars-item">
                            <p className="view-car-title">Mark:</p>
                            <p className="view-car-value">{data.mark === "null" ? "Empty" : data.mark}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Model:</p>
                            <p className="view-car-value">{data.model === "null" ? "Empty" : data.model}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Year:</p>
                            <p className="view-car-value">{data.year === "null" ? "Empty" : data.year}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Color:</p>
                            <p className="view-car-value">{data.color === "null" ? "Empty" : data.color}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">vin:</p>
                            <p className="view-car-value" onClick={() => copyToClipboard(data.vin)}>{(typeof data.vin !== "undefined" && data.vin != "null") && <><span>{data.vin.slice(0, -6)}</span><span style={{ color: "red", fontWeight: "bold" }}>{data.vin.slice(-6)}</span></>}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Key:</p>
                            <p className="view-car-value">{data.has_key === "null" ? "Empty" : data.has_key}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">title:</p>
                            <p className="view-car-value">{data.title === "null" ? "Empty" : data.title}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Auction:</p>
                            <p className="view-car-value">{data.auction === "null" ? "Empty" : data.auction}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Lot:</p>
                            <p className="view-car-value" onClick={() => copyToClipboard(data.lot)}>{data.lot === "null" ? "Empty" : data.lot}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">State:</p>
                            <p className="view-car-value">{data.state === "null" ? "Empty" : data.state}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Load state:</p>
                            <p className="view-car-value">{data.load_state === "null" ? "Empty" : data.load_state}</p>
                        </div>
                    </div>
                    <div className="col-md-4 border-left">
                        <div className="view-cars-item">
                            <p className="view-car-title">Auction city:</p>
                            <p className="view-car-value">{data.auction_city === "null" ? "Empty" : data.auction_city}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Payment date:</p>
                            <p className="view-car-value">{data.payment_date === "null" ? "Empty" : data.payment_date}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Container number:</p>
                            <p className="view-car-value" onClick={() => copyToClipboard(data.container_number)}>{data.container_number === "null" ? "Empty" : data.container_number}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Container location:</p>
                            <Link to={`/container-tracking?containerNumber=${data.container_number}`} target="_blank" className="view-car-value">{data.container_number === "null" ? "Empty" : data.container_number}</Link>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Sea line:</p>
                            <a href={data.sea_line} style={{ cursor: "pointer" }} target={"_blank"} className="view-car-value">Go to line</a>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Container arrival date:</p>
                            <p className="view-car-value">{data.arrival_date === "null" ? "Empty" : data.arrival_date}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Price:</p>
                            <p className="view-car-value">{data.price === "null" ? "Empty" : data.price}{data.price !== "null" && '$'}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Destination port:</p>
                            <p className="view-car-value">{data.destination_port === "null" ? "Empty" : data.destination_port}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Tracking URL:</p>
                            <p className="view-car-value">{data.tracking_url === "null" ? "Empty" : data.tracking_url}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Auction_images:</p>
                            <p className="view-car-value">Show</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Warehouse images:</p>
                            <a href={data.warehouse_images} target="_blank" className="view-car-value">Download</a>
                        </div>
                    </div>
                    <div className="col-md-4 border-left">
                        <div className="view-cars-item">
                            <p className="view-car-title">Fine:</p>
                            <p className="view-car-value">{data.fine ? "Yes" : "No"}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Document:</p>
                            <p className="view-car-value">{data.document === "null" ? "Empty" : data.document}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">EV hybrid:</p>
                            <p className="view-car-value">{data.ev_hybrid ? "Yes" : "No"}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Invoice original:</p>
                            <a href={data.invoice_original} target={"_blank"} className="view-car-value">{(data.invoice_original != "null" && typeof data.invoice_original !== "undefined" && data.invoice_original != null) ? data.invoice_original.slice(0, 35) : "Empty"}...</a>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Invoice generated:</p>
                            <a href={data.invoice_generated} target={"_blank"} className="view-car-value">{(data.invoice_generated != "null" && typeof data.invoice_generated !== "undefined" && data.invoice_generated != null) ? data.invoice_generated.slice(0, 35) : "Empty"}...</a>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Insurance:</p>
                            <p className="view-car-value">{data.insurance ? "Yes" : "No"}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Transportation payment date:</p>
                            <p className="view-car-value">{data.transportation_payment_date === "null" ? "Empty" : data.transportation_payment_date}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Received:</p>
                            <p className="view-car-value">{data.receive_status ? "Yes" : "No"}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Receiver:</p>
                            <p className="view-car-value">{data.receiver === "null" ? "Empty" : data.receiver}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Is sublot:</p>
                            <p className="view-car-value">{data.is_sublot ? "Yes" : "No"}</p>
                        </div>
                        <div className="view-cars-item">
                            <p className="view-car-title">Comment:</p>
                            <p className="view-car-value">{data.comment}</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewCarsModal