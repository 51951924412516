import axios from "axios";
import { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";

const Users = () => {
    const [total, setTotal] = useState(0);
    const [pageChanged, setPageChanged] = useState(1);
    const [users, setUsers] = useState([]);
    const [notification, setNotification] = useState({
        message: "",
        body: "",
        show: false
    });
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [active, setActive] = useState({});
    const [addUsersModal, setAddUserModal] = useState(false);
    const [formData, setFormData] = useState({
        name: "", surname: "", ID_number: ""
    });
    const [editUserModal, setEditUserModal] = useState(false);

    useEffect(() => {
        axios(`/api/users/receivers?limit=10&page=${pageChanged}`)
            .then((res) => {
                const data = res.data.data;
                setUsers([...data]);
                setTotal(res.data.total)
            })
            .catch((err) => {
                setNotification({
                    message: "Server side error",
                    body: "Cars not loaded",
                    show: true
                })
            })
    }, [pageChanged]);

    const handlers = {
        async saveUser() {
            try {
                const response = axios.put(`/api/users/receivers/${active.id}`, formData)
                const data = users.map((u) => {
                    if (u.phone === active.phone) {
                        return { ...u, name: formData.name, surname: formData.surname, id_number: formData.id_number }
                    } else {
                        return { ...u }
                    }
                })
                setEditUserModal(false);

                setUsers([...data]);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.body,
                    show: true
                })
            }
        },
        async addUser() {
            try {
                const response = axios.post(`/api/users/receiver`, formData)
                const data = [...users, { ...formData }];
                setAddUserModal(false);
                setUsers([...data]);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.body,
                    show: true
                })
            }
        },
        deleteUser() {
            try {
                const response = axios.delete(`/api/users/receivers/${active.id}`);
                const data = users.filter((u) => u.phone != active.phone);
                setShowDeleteUser(false);
                setUsers([...data]);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.body,
                    show: true
                })
            }
        }
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "30px" }}>
                <Button onClick={() => {
                    setAddUserModal(true);
                    setFormData({
                        name: "", surname: "", email: "", phone: ""
                    })
                }} variant='dark'>Add new</Button>
            </div>
            <Table responsive className="responsive-data-table">
                <thead>
                    <tr className="table-header">
                        <th className="table-header-items">Name</th>
                        <th className="table-header-items">Surname</th>
                        <th className="table-header-items">ID number</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((user) => {
                            return (
                                <tr>
                                    <td>{user.name}</td>
                                    <td>{user.surname}</td>
                                    <td>{user.id_number}</td>
                                    <td className="table-action-items">
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} src="https://usdelivery.ge/static/image/edit.svg" alt="edit" onClick={() => {
                                            setActive({ ...user });
                                            setFormData({
                                                name: user.name, surname: user.surname, ID_number: user.id_number
                                            });
                                            setEditUserModal(true);
                                        }} />
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} src="https://cdn-icons-png.flaticon.com/512/484/484662.png" alt="edit" onClick={() => {
                                            setActive({ ...user });
                                            setShowDeleteUser(true);
                                        }} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        users.length === 0 && (
                            <tr>
                                <td colSpan="8">
                                    <div className="empty-records"><p>Records not found</p></div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <div className={"pagonation-content"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                    {'<<'}
                </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return
                                } else {
                                    return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                        setPageChanged(p + 1)
                                    }
                                    }>
                                    {p + 1}
                                </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                    {'>>'}
                </span>}
                </div>
                <p><strong>Total:</strong> {total}</p>
            </div>
            <Modal show={notification.show} onHide={() => {
                setNotification((prev) => ({ ...prev, show: false }))
            }} >
                <Modal.Header>
                    <h3>{notification.message}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h4>{notification.body}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {
                        setNotification((prev) => ({ ...prev, show: false }))
                    }}>Ok</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={editUserModal} onHide={() => setEditUserModal(false)} size="md" centered>
                <Modal.Header>
                    <h3>Edit user</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form className="">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" value={formData.name} className="form-control" placeholder="Enter name" onChange={(e) => {
                                        if (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === '') {
                                            setFormData((prev) => ({ ...prev, name: e.target.value.toUpperCase() }))
                                        }
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label>Surname</label>
                                    <input type="text" value={formData.surname} className="form-control" placeholder="Enter surname" onChange={(e) => {
                                        if (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === '') {
                                            setFormData((prev) => ({ ...prev, surname: e.target.value.toUpperCase() }))
                                        }
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label>ID number</label>
                                    <input type="text" value={formData.ID_number} className="form-control" placeholder="Enter email" onChange={(e) => setFormData((prev) => ({ ...prev, ID_number: e.target.value }))} />
                                </div>
                                <Button onClick={handlers.saveUser} variant="dark" style={{ width: "100%" }}>Save</Button>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={addUsersModal} onHide={() => setAddUserModal(false)} size="md" centered>
                <Modal.Header>
                    <h3>Add new user</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <form className="">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" value={formData.name} className="form-control" placeholder="Enter name" onChange={(e) => {
                                        if (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === '') {
                                            setFormData((prev) => ({ ...prev, name: e.target.value.toUpperCase() }))
                                        }
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label>Surname</label>
                                    <input type="text" value={formData.surname} className="form-control" placeholder="Enter surname" onChange={(e) => {
                                        if (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === '') {
                                            setFormData((prev) => ({ ...prev, surname: e.target.value.toUpperCase() }))
                                        }
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label>ID number</label>
                                    <input type="text" value={formData.ID_number} className="form-control" placeholder="Enter email" onChange={(e) => setFormData((prev) => ({ ...prev, ID_number: e.target.value }))} />
                                </div>
                                <Button onClick={handlers.addUser} variant="dark" style={{ width: "100%" }}>Save</Button>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteUser} onHide={() => setShowDeleteUser(false)} size='md'>
                <Modal.Header>
                    <h3>Do you want to delete user?</h3>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button variant="danger" onClick={handlers.deleteUser}>Yes</Button>
                        <Button variant="dark" onClick={() => setShowDeleteUser(false)} style={{ marginLeft: "15px" }}>No</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Users