import axios from "axios"
import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap"
import { useSelector } from "react-redux";

const AddCarsModal = ({ show, hide, data, setData, submitFormHandler, actionType }) => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const role = useSelector((state) => state.user.role);
    const [dealers, setDealers] = useState([]);
    const [filter, setFilter] = useState({
        auction: "", state: "", port: "", price: "", fee: ""
    })
    const [treeCars, setThreeCars] = useState(false);
    const [showImages, setShowImages] = useState(false);
    const [price, setPrice] = useState({
        state: [], port: []
    })
    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    const handleFileUpload = () => {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('images', selectedFiles[i]);
        }
        formData.append('vin', data.vin);
        setLoading(true)


        // Adjust the URL to match your Express server route for handling file uploads
        axios.post('/api/cars/user/images', formData)
            .then((response) => {
                console.log('Upload successful:', response.data);
                alert("Images uploaded successfully");
                const images = response.data.data;
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                alert("Something went wrong");
            });
    };

    const handlers = {
        async searchByVin(e) {
            if (e.keyCode === 13) {
                try {
                    const response = await axios.get(`/api/cars/details?vin=${data.vin}`);
                    const car = response.data.data.attributes;
                    setData((prev) => {
                        return {
                            ...prev,
                            mark: car.make,
                            model: car.model,
                            year: car.year,
                            lot: car.lot,
                            color: car.color
                        }
                    })
                } catch (err) {
                    alert("Car not found")
                }
            }
        }, async getStates(e) {
            try {
                const response = await axios.get(`/api/calculator/load/states?auction=${e}`);
                const states = response.data.data;
                setPrice({ state: [...states], port: [] })
            } catch (err) {
                alert("States not found")
            }
        }, async getPorts(e) {
            try {
                const response = await axios.get(`/api/calculator/load/port?state=${e}&auction=${data.auction}`);
                const ports = response.data.data;
                setPrice((prev) => ({ ...prev, port: [...ports] }))
            } catch (err) {
                alert("Ports not found")
            }
        }, async getPrice(e) {
            try {
                const response = await axios.get(`/api/calculator/load/get-price?state=${data.state}&auction=${data.auction}&port=${e}`);
                let price = response.data.data[0];
                price = parseInt(price.price) + parseInt(price.fee);
                setData((prev) => ({ ...prev, price: price }))
            } catch (err) {
                alert("Ports not found")
            }
        }
    }

    useEffect(() => {
        setUsers([]);
        if (role == "user") {
            axios('/api/users/receivers?page=1&limit=100')
                .then((res) => {
                    const data = res.data.data;
                    setUsers([...data])
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            axios('/api/admin/users?page=1&limit=500&desc=no')
                .then((res) => {
                    const data = res.data.data;
                    setDealers((prev) => ([...prev, ...data]));
                })
                .catch((err) => {
                    console.log(err)
                })

            axios('/api/admin/legal?page=1&limit=500&desc=no')
                .then((res) => {
                    const data = res.data.data;
                    setDealers((prev) => ([...prev, ...data]));
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [])

    return (<Modal show={show} onHide={hide} centered size="lg">
        <Modal.Header>
            <h3>{actionType === "add" ? "Add new Car" : "Edit car"}</h3>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={submitFormHandler} onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    e.preventDefault()
                }
            }}>
                <div className="row">
                    {actionType !== "edit" && <div className={"col-md-6"}>
                        {actionType !== "edit" && <div className="form-group">
                            <label>VIN</label>
                            <input type="text" required onKeyDown={handlers.searchByVin} value={data.vin}
                                onChange={(e) => setData((prev) => ({
                                    ...prev, vin: e.target.value.toUpperCase()
                                }))} className="form-control" placeholder="Search by VIN" />
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>Mark</label>
                            <input type="text" required value={data.mark} onChange={(e) => setData((prev) => ({
                                ...prev, mark: e.target.value.toUpperCase()
                            }))} className="form-control" />
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>Model</label>
                            <input type="text" required value={data.model} className="form-control"
                                onChange={(e) => setData((prev) => ({
                                    ...prev, model: e.target.value.toUpperCase()
                                }))} />
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>Year</label>
                            <input type="text" required value={data.year} className="form-control"
                                onChange={(e) => setData((prev) => ({
                                    ...prev, year: e.target.value.toUpperCase()
                                }))} />
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>color</label>
                            <input type="text" required value={data.color} className="form-control"
                                onChange={(e) => setData((prev) => ({
                                    ...prev, color: e.target.value.toUpperCase()
                                }))} />
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>Document</label>
                            <input required className="form-control" value={data.document} onChange={(e) => setData((prev) => ({
                                ...prev,
                                document: e.target.value.toUpperCase()
                            }))} />
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>Title</label>
                            <select required className="form-control" value={data.title} onChange={(e) => setData((prev) => ({
                                ...prev,
                                title: e.target.value.toUpperCase()
                            }))} >
                                <option >Select title</option>
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                            </select>
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>Lot</label>
                            <input type="text" required value={data.lot} className="form-control" onChange={(e) => {
                                setData((prev) => ({ ...prev, lot: e.target.value.toUpperCase() }));
                            }} />
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>Buyer</label>
                            <input type="text" required value={data.buyer} className="form-control" onChange={(e) => {
                                setData((prev) => ({ ...prev, buyer: e.target.value }));
                            }} />
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>Transportation price</label>
                            <input type="text" required value={data.price} className="form-control"
                                onChange={(e) => setData((prev) => ({
                                    ...prev, price: e.target.value.toUpperCase()
                                }))} />
                        </div>}
                    </div>}
                    <div className={actionType !== "edit" ? "col-md-6" : "col-me-12"}>
                        {role === "admin" && <div className="form-group">
                            <label>Linked user</label>
                            {/* <input type="text" required value={data.linked_user} className="form-control" onChange={(e) => setData((prev) => ({ ...prev, linked_user: e.target.value.toUpperCase() }))} /> */}
                            <select className="form-control" value={data.linked_user} required
                                onChange={(e) => setData((prev) => ({
                                    ...prev, linked_user: e.target.value.toUpperCase()
                                }))}>
                                <option>Select user</option>
                                {dealers.map((d) => {
                                    return (typeof d.companyName == "undefined" ? (<option key={d.ID_number}
                                        value={d.id}>{`${d.id} - ${d.name} ${d.surname} - ${d.phone}`}</option>) : (

                                        <option key={d.taxId}
                                            value={d.taxId}>{`${d.id} - ${d.companyName} - ${d.phone}`}</option>))
                                })}
                            </select>
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>Auction</label>
                            <select className="form-control" value={data.auction} required onChange={(e) => {
                                setFilter((prev) => ({ ...prev, auction: e.target.value }));
                                setData((prev) => ({ ...prev, auction: e.target.value }));
                                handlers.getStates(e.target.value);
                            }}>
                                <option>Select auction</option>
                                <option value="copart">Copart</option>
                                <option value="iaai">IAAI</option>
                            </select>
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>State</label>
                            <select required value={data.state} className="form-control" onChange={(e) => {
                                setData((prev) => ({ ...prev, state: e.target.value }));
                                handlers.getPorts(e.target.value)
                            }}>
                                <option>Choose state</option>
                                {price.state.map((s) => {
                                    return (<option value={s.state} key={s.id}>{s.state}</option>)
                                })}
                            </select>
                        </div>}
                        {actionType !== "edit" && <div className="form-group">
                            <label>Port</label>
                            <select required value={data.port} className="form-control" onChange={(e) => {
                                setData((prev) => ({ ...prev, port: e.target.value }));
                                handlers.getPrice(e.target.value);
                            }}>
                                <option>Choose port</option>
                                {price.port.map((p, l) => {
                                    return (<option value={p.port} key={l * 50}>{p.port}</option>)
                                })}
                            </select>
                        </div>}
                        {role === "user" && <div className="form-group">
                            <label>Receiver</label>
                            <input type="text" required value={data.receiver} className="form-control"
                                onChange={(e) => setData((prev) => ({
                                    ...prev, receiver: e.target.value.toUpperCase()
                                }))} />
                            {/*<select required value={data.receiver} className="form-control"*/}
                            {/*        onChange={(e) => setData((prev) => ({*/}
                            {/*            ...prev, receiver: e.target.value.toUpperCase()*/}
                            {/*        }))}>*/}
                            {/*    <option value={""}>Select user</option>*/}
                            {/*    <option value={"owner"}>I own this car</option>*/}
                            {/*    {users.map((u) => {*/}
                            {/*        return <option*/}
                            {/*            value={`${u.name} ${u.surname}`}>{`${u.name} ${u.surname}`}</option>*/}
                            {/*    })}*/}
                            {/*</select>*/}
                        </div>}
                        <div className="form-group">
                            <label>Comment</label>
                            <textarea value={data.comment} className="form-control" style={{ height: "125px" }}
                                onChange={(e) => setData((prev) => ({
                                    ...prev, comment: e.target.value.toUpperCase()
                                }))} />
                        </div>
                        <div className="form-group checkbox-container">
                            <input value={data.insurance} defaultChecked={[data].some(i => i.insurance === true)}
                                id="has-insurance" type="checkbox"
                                onChange={(e) => setData((prev) => ({ ...prev, insurance: e.target.checked }))} />
                            <label htmlFor="has-insurance">Insurance</label>
                            {actionType !== "edit" && <><input value={data.has_key}
                                defaultChecked={[data].some(i => {
                                    return i.has_key === true
                                })} id="has-key" type="checkbox"
                                onChange={(e) => setData((prev) => ({
                                    ...prev, has_key: e.target.checked
                                }))} />
                                <label htmlFor="has-key">Key</label>
                                <input value={data.is_sublot}
                                    defaultChecked={[data].some(i => i.is_sublot === true)}
                                    id="is-sublot" type="checkbox"
                                    onChange={(e) => setData((prev) => ({
                                        ...prev, is_sublot: e.target.checked
                                    }))} />
                                <label htmlFor="is-sublot">Sublot</label>
                            </>}
                        </div>
                        {actionType === "edit" && <>
                            <div className={"upload-car-data-images"}>
                                <input multiple onChange={handleFileChange} type={"file"} />
                                <Button disabled={loading ? true : false} onClick={handleFileUpload}
                                    variant={"info"}>Upload</Button>
                                {loading && <span style={{ marginLeft: "8px" }}>Uploading...</span>}
                            </div>
                            <div className={"show-hide-images"}>
                                <input onClick={() => setShowImages(!showImages)} id={"show-images"}
                                    type={"checkbox"} />
                                <label htmlFor={"show-images"}>Show / hide images</label>
                            </div>
                        </>}
                        {showImages && <div className={"user-uploaded-images-container"}>
                            {
                                data.user_uploaded_images && data.user_uploaded_images.map((i) => {
                                    return <img src={i} style={{ width: "100%", marginTop: "10px" }} alt={"cars"} />
                                })
                            }
                        </div>}
                    </div>
                    <div className="col-md-12">
                        <Button type="submit" style={{ width: "100%" }}
                            variant="dark">{actionType === "add" ? "Add" : "Save"}</Button>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>)
}

export default AddCarsModal
