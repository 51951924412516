import {Button, Modal, Table} from 'react-bootstrap';
import TableHeader from "../../Common/TableHeader/TableHeader";
import {useEffect, useState} from "react";
import axios from "axios";
import './Invoice.css'

const Invoice = ({role}) => {
    const [invoices, setInvoices] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [type, setType] = useState("");
    const [pageChanged, setPageChanged] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [notification, setNotification] = useState({
        message: "",
        body: "",
        show: false
    });
    const [active, setActive] = useState({});
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    useEffect(() => {
        axios(`/api/invoices?page=${pageChanged}&limit=${limit}&keyword=${keyword}&type=${type}`)
            .then((res) => {
                const data = res.data.data;
                setTotal(res.data.total);
                setInvoices([...data]);
            })
            .catch((err) => {
                setNotification({
                    message: "Server side error",
                    body: "Invoices not loaded",
                    show: true
                })
            })
    }, [pageChanged, keyword, limit, type]);
    return (
        <>
            <TableHeader keyword={setKeyword} search={true} withSelect={true} selectOnChange={setType} />
            <Table responsive className="responsive-data-table">
                <thead>
                <tr>
                    <th>VIN</th>
                    <th>ID number</th>
                    <th>Mark</th>
                    <th>Model</th>
                    <th>Payed</th>
                    <th>Type</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    invoices.map((i) => {
                        return(
                            <tr key={i.id}>
                                <td>{i.vin}</td>
                                <td>{i.ID_number}</td>
                                <td>{i.mark}</td>
                                <td>{i.model}</td>
                                <td>{i.payed == 1 ? <img style={{ width: "20px" }} src="/static/icons/check.png" alt="Yes" /> : <img style={{ width: "20px" }} src="/static/icons/cross.png" alt="No" />}</td>
                                <td>{i.invoice_type}</td>
                                <td className={"table-action-items"}>
                                    <a style={{marginRight: "10px"}} href={i.file_name} target={"_blank"}>
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} src={"https://cdn.icon-icons.com/icons2/1673/PNG/512/downloadoutline_110860.png"} alt="view" />
                                    </a>
                                    {role !== "user" && <img
                                        src={"https://cdn-icons-png.flaticon.com/512/269/269016.png"}
                                        className="actions-icon"
                                        alt={"change status"}
                                        onClick={() => {
                                            setShowPaymentModal(true);
                                            setActive({...i});
                                        }}
                                    />}
                                </td>
                            </tr>
                        )
                    })
                }
                {
                    invoices.length === 0 && (
                        <tr>
                            <td colSpan="13">
                                <div className="empty-records"><p>Records not found</p></div>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>

            <div className={"pagonation-content"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                    {'<<'}
                </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return
                                } else {
                                    return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                        setPageChanged(p + 1)
                                    }
                                    }>
                                    {p + 1}
                                </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                    {'>>'}
                </span>}
                </div>
                <p><strong>Total:</strong> {total}</p>
            </div>

            <Modal show={notification.show} onHide={() => {
                setNotification((prev) => ({...prev, show: false}))
            }}>
                <Modal.Header>
                    <h3>{notification.message}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h4>{notification.body}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {
                        setNotification((prev) => ({...prev, show: false}))
                    }}>Ok</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)} size="md">
                <Modal.Header><h3>Is this invoice paid?</h3></Modal.Header>
                <Modal.Body>
                    <div className={"modal-buttons"}>
                        <Button variant={"dark"} style={{marginRight: "30px"}} onClick={() => {
                            setShowPaymentModal(false);
                            axios.put(`/api/invoices/${active.id}`, {payed: true})
                                .then((res) => {
                                    setNotification({
                                        message: "Success",
                                        body: "Status changed",
                                        show: true
                                    });
                                    const data = invoices.map((i) => {
                                        if(i.id === active.id){
                                            return {...i, payed: 1}
                                        }else{
                                            return {...i}
                                        }
                                    })

                                    setInvoices([...data])
                                })
                                .catch(() => {
                                    setNotification({
                                        message: "Server side error",
                                        body: "Status not updated",
                                        show: true
                                    })
                                })
                        }}>Yes</Button>
                        <Button variant={"danger"} onClick={() => {
                            setShowPaymentModal(false);
                            axios.put(`/api/invoices/${active.id}`, {payed: false})
                                .then((res) => {
                                    setNotification({
                                        message: "Success",
                                        body: "Status changed",
                                        show: true
                                    })
                                    const data = invoices.map((i) => {
                                        if(i.id === active.id){
                                            return {...i, payed: 0}
                                        }else{
                                            return {...i}
                                        }
                                    })

                                    setInvoices([...data])
                                })
                                .catch(() => {
                                    setNotification({
                                        message: "Server side error",
                                        body: "Status not updated",
                                        show: true
                                    })
                                })
                        }}>No</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Invoice