import { useState } from "react"
import { Button, Modal } from "react-bootstrap"

const AddCalculatorItemModal = ({ type, show, hide, data, onSubmitHandler, setData }) => {
    const [port, setPort] = useState({ name: "", price: "" });
    return (
        <Modal show={show} onHide={() => hide(false)} centered size="md">
            <Modal.Header><h3>{type === "add" ? "Add new record" : "Edit record"}</h3></Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmitHandler}>
                    <div className="form-group">
                        <label>Auction</label>
                        <select value={data.auction} required className="form-control" onChange={(e) => setData((prev) => ({ ...prev, auction: e.target.value }))}>
                            <option>Select auction</option>
                            <option value="COPART">Copart</option>
                            <option value="IAAI">IAAI</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>State</label>
                        <input value={data.state} type="text" className="form-control" onChange={(e) => {
                            setData((prev) => {
                                return { ...prev, state: e.target.value }
                            })
                        }} />
                    </div>
                    <div className="form-group">
                        <label>Port</label>
                        <input value={data.port} type="text" className="form-control" onChange={(e) => {
                            setData((prev) => {
                                return { ...prev, port: e.target.value }
                            })
                        }} />
                    </div>
                    <div className="form-group">
                        <label>Price</label>
                        <input value={data.price} type="text" className="form-control" onChange={(e) => {
                            setData((prev) => {
                                return { ...prev, price: e.target.value }
                            })
                        }} />
                    </div>
                    <div className="form-group">
                        <label>Fee</label>
                        <input required value={data.fee} type="text" className="form-control" onChange={(e) => {
                            setData((prev) => ({ ...prev, fee: e.target.value }));
                        }} />
                    </div>
                    {type === "add" && <Button variant="dark" type="submit" style={{ width: "100%" }}>Add</Button>}
                    {type === "edit" && <Button variant="dark" type="submit" style={{ width: "100%" }}>Save</Button>}
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddCalculatorItemModal