import { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import './Auth.css';
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { usersActions } from '../../store/user';

const Auth = () => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const [notification, setNotification] = useState({
        message: "",
        body: "",
        show: false
    })
    const [formData, setFormData] = useState({
        username: "",
        password: ""
    })

    const handlers = {
        async auth(e) {
            setLoader(true);
            e.preventDefault();
            try {
                const response = await axios.post('/api/users/auth', formData);
                const data = response.data;
                setNotification({
                    message: "Success",
                    body: "Access granted",
                    show: true
                });
                dispatch(usersActions.changeIsLoggedIn(true));
                window.location.reload()
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.message,
                    show: true
                });
            }
        }
    }

    return (
        <>
            <div className="auth-content-container">
                <div className="auth-content-box">
                    <div className="auth-content">
                        <h2 className="auth-header">Log In</h2>
                        <form className="form-group" onSubmit={handlers.auth}>
                            <div className="form-group">
                                <label htmlFor="username" className='form-label col-form-label'>Username</label>
                                <input className='form-control' required onChange={(e) => setFormData((prev) => ({ ...prev, username: e.target.value }))} id="username" placeholder='Enter username' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" className='form-label'>Password</label>
                                <input className='form-control' type='password' required onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))} id="password" placeholder='Enter password' />
                            </div>
                            <button type="submit" disabled={loader} className="btn btn-dark auth-button">
                                {loader ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    'Log In'
                                )}
                            </button>
                        </form>
                        <Link>
                            Reset password
                        </Link>
                    </div>
                </div>
            </div>
            <Modal show={notification.show} onHide={() => {
                setNotification((prev) => ({ ...prev, show: false }))
                setLoader(false);
            }} >
                <Modal.Header>
                    <h3>{notification.message}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h4>{notification.body}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {
                        setNotification((prev) => ({ ...prev, show: false }))
                        setLoader(false);
                    }}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Auth