import axios from "axios"
import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap"
import { useSelector } from "react-redux";

const EditCarsAdmin = ({ show, hide, data, cars, setCars, setData, submitFormHandler, actionType }) => {
    const role = useSelector((state) => state.user.role);
    const [showRemoveCarModal, setShowRemoveCarModal] = useState(false);
    const [dealers, setDealers] = useState([]);
    const [price, setPrice] = useState({
        state: [],
        port: []
    });
    const [filter, setFilter] = useState({
        auction: "",
        state: "",
        port: "",
        price: "",
        fee: ""
    })

    const handlers = {
        async searchByVin(e) {
            if (e.keyCode === 13) {
                try {
                    const response = await axios.get(`/api/cars/details?vin=${data.vin}`);
                    const car = response.data.data.attributes;
                    const model = car.name.split(' ');
                    setData((prev) => {
                        return {
                            ...prev,
                            mark: car.maker,
                            model: car.model.replace(car.maker + "_", "").replaceAll("_", " "),
                            year: model[0],
                            auction_city: car.city,
                            state: car.region,
                            lot: response.data.data.id
                        }
                    })
                } catch (err) {
                    alert("Car not found")
                }
            }
        },
        async getStates(e) {
            try {
                const response = await axios.get(`/api/calculator/load/states?auction=${e}`);
                const states = response.data.data;
                setPrice({ state: [...states], port: [] })
            } catch (err) {
                alert("States not found")
            }
        },
        async getPorts(e) {
            try {
                const response = await axios.get(`/api/calculator/load/port?state=${e}&auction=${data.auction}`);
                const ports = response.data.data;
                setPrice((prev) => ({ ...prev, port: [...ports] }))
            } catch (err) {
                alert("Ports not found")
            }
        },
        async getPrice(e) {
            try {
                const response = await axios.get(`/api/calculator/load/get-price?state=${data.state}&auction=${data.auction}&port=${e}`);
                let price = response.data.data[0];
                price = parseInt(price.price) + parseInt(price.fee);
                setData((prev) => ({ ...prev, price: price }))
            } catch (err) {
                alert("Ports not found")
            }
        },
        async deleteCar() {
            try {
                const response = await axios.delete(`/api/cars/${data.vin}`);
                const d = cars.filter((c) => c.vin !== data.vin);
                setCars([...d])
                hide(false);
                setShowRemoveCarModal(false);
            } catch (err) {
                setShowRemoveCarModal(false);
                alert("Something went wrong")
            }
        }
    }

    useEffect(() => {
        if (actionType === "edit" && show) {
            setDealers([]);
            handlers.getStates(data.auction);
            handlers.getPorts(data.state);
            axios('/api/admin/users?page=1&limit=500&desc=no')
                .then((res) => {
                    const data = res.data.data;
                    setDealers((prev) => ([...prev, ...data]));
                })
                .catch((err) => {
                    console.log(err)
                })

            axios('/api/admin/legal?page=1&limit=500&desc=no')
                .then((res) => {
                    const data = res.data.data;
                    setDealers((prev) => ([...prev, ...data]));
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [show])

    return (
        <>
            <Modal show={show} onHide={() => hide(false)} centered size="xl">
                <Modal.Header>
                    <h3>{actionType === "add" ? "Add new Car" : `Edit car | Receiver: ${data.receiver}`}</h3>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitFormHandler} onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            e.preventDefault()
                        }
                    }}>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>VIN</label>
                                    <input type="text" required onKeyDown={handlers.searchByVin} value={data.vin}
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            vin: e.target.value.toUpperCase()
                                        }))} className="form-control" placeholder="Search by VIN" />
                                </div>
                                <div className="form-group">
                                    <label>Lot</label>
                                    <input type="text" required value={data.lot} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            lot: e.target.value.toUpperCase()
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Mark</label>
                                    <input type="text" required value={data.mark} onChange={(e) => setData((prev) => ({
                                        ...prev,
                                        mark: e.target.value.toUpperCase()
                                    }))} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Model</label>
                                    <input type="text" required value={data.model} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            model: e.target.value.toUpperCase()
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Year</label>
                                    <input type="text" required value={data.year} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            year: e.target.value.toUpperCase()
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Color</label>
                                    <input type="text" required value={data.color} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            color: e.target.value.toUpperCase()
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Document</label>
                                    <select required className="form-control" value={data.document} onChange={(e) => setData((prev) => ({
                                        ...prev,
                                        document: e.target.value.toUpperCase()
                                    }))} >
                                        <option >Select document</option>
                                        <option value="YES">Yes</option>
                                        <option value="NO">No</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Title</label>
                                    <select required className="form-control" value={data.title} onChange={(e) => setData((prev) => ({
                                        ...prev,
                                        title: e.target.value.toUpperCase()
                                    }))} >
                                        <option >Select title</option>
                                        <option value="YES">Yes</option>
                                        <option value="NO">No</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Buyer</label>
                                    <input type="text" required value={data.buyer} className="form-control" onChange={(e) => {
                                        setData((prev) => ({ ...prev, buyer: e.target.value.toUpperCase() }));
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label>Status</label>
                                    <select className="form-control" value={data.status} required onChange={(e) => {
                                        setData((prev) => ({ ...prev, status: e.target.value }));
                                    }}>
                                        <option>Select status</option>
                                        <option value="warehouse">Warehouse</option>
                                        <option value="on the way">On the way</option>
                                        <option value="arrived">Arrived</option>
                                        <option value="withdrawn">Withdrawn</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Warehouse images</label>
                                    <input type="text" value={data.warehouse_images} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            warehouse_images: e.target.value.toUpperCase()
                                        }))} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Auction</label>
                                    <select className="form-control" value={data.auction} required onChange={(e) => {
                                        setFilter((prev) => ({ ...prev, auction: e.target.value }));
                                        setData((prev) => ({ ...prev, auction: e.target.value }));
                                        handlers.getStates(e.target.value);
                                    }}>
                                        <option>Select auction</option>
                                        <option value="copart">Copart</option>
                                        <option value="iaai">IAAI</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Auction city</label>
                                    <select required value={data.state} className="form-control" onChange={(e) => {
                                        setData((prev) => ({ ...prev, state: e.target.value }));
                                        handlers.getPorts(e.target.value)
                                    }}>
                                        <option>Choose state</option>
                                        {
                                            price.state.map((s) => {
                                                return (
                                                    <option value={s.state} key={s.id}>{s.state}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Loading port</label>
                                    <select required value={data.port} className="form-control" onChange={(e) => {
                                        setData((prev) => ({ ...prev, port: e.target.value }));
                                        handlers.getPrice(e.target.value);
                                    }}>
                                        <option>Choose port</option>
                                        {
                                            price.port.map((p, l) => {
                                                return (
                                                    <option value={p.port} key={l * 50}>{p.port}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Price</label>
                                    <input type="text" value={data.price} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            price: e.target.value.toUpperCase()
                                        }))} />
                                </div>
                                {role === "admin" && <div className="form-group">
                                    <label>Linked user</label>
                                    {/* <input type="text" required value={data.linked_user} className="form-control" onChange={(e) => setData((prev) => ({ ...prev, linked_user: e.target.value.toUpperCase() }))} /> */}
                                    <select className="form-control" value={data.linked_user} required
                                        onChange={(e) => setData((prev) => ({
                                            ...prev, linked_user: e.target.value
                                        }))}>
                                        <option>Select user</option>
                                        {dealers.map((d) => {
                                            return (typeof d.companyName == "undefined" ? (<option key={d.ID_number}
                                                value={d.id}>{`${d.id} - ${d.name} ${d.surname} - ${d.phone}`}</option>) : (

                                                <option key={d.taxId}
                                                    value={d.taxId}>{`${d.id} - ${d.companyName} - ${d.phone}`}</option>))
                                        })}
                                    </select>
                                </div>}
                                <div className="form-group">
                                    <label>Tracking URL</label>
                                    <input type="text" value={data.tracking_url} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            tracking_url: e.target.value
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Destination port</label>
                                    <input type="text" value={data.destination_port} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            destination_port: e.target.value.toUpperCase()
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Container number</label>
                                    <input type="text" value={data.container_number} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            container_number: e.target.value.toUpperCase()
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Sea line</label>
                                    <select className={"form-control"} onChange={(e) => setData((prev) => ({
                                        ...prev,
                                        sea_line: e.target.value
                                    }))}>
                                        <option>Select line</option>
                                        <option value={"https://www.maersk.com/tracking/"}>MAERSK</option>
                                        <option value={"https://ct.shipmentlink.com/servlet/TDB1_CargoTracking.do"}>EVERGREEN</option>
                                        <option value={"https://www.msc.com/en/track-a-shipment"}>MSC</option>
                                        <option value={"https://www.cma-cgm.com/ebusiness/tracking"}>CMA CGM</option>
                                        <option value={"https://www.hapag-lloyd.com/en/online-business/track/track-by-container-solution.html"}>HAPAG LLOYD</option>
                                        <option value={"https://my.turkon.com/container-tracking"}>TURKOM</option>
                                        <option value={"https://www.zim.com/tools/track-a-shipment"}>ZIM</option>
                                        <option value={"https://ecomm.one-line.com/one-ecom/manage-shipment/cargo-tracking"}>ONE LINE</option>
                                        <option value={"https://elines.coscoshipping.com/ebusiness/cargoTracking"}>COSCO</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Payment date</label>
                                    <input type="date" value={data.payment_date} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            payment_date: e.target.value
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Container arrival date</label>
                                    <input type="date" value={data.arrival_date} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            arrival_date: e.target.value
                                        }))} />
                                </div>
                            </div>
                            <div className={"col-md-4"}>
                                <div className="form-group">
                                    <label>Transportation payment date</label>
                                    <input type="date" value={data.transportation_payment_date} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            transportation_payment_date: e.target.value
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Warehouse receive date</label>
                                    <input type="date" value={data.warehouse_receive_date} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            warehouse_receive_date: e.target.value
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Loading date</label>
                                    <input type="date" value={data.loading_date} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            loading_date: e.target.value
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Invoice original</label>
                                    <input type="text" value={data.invoice_original} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            invoice_original: e.target.value
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Invoice generated</label>
                                    <input type="text" value={data.invoice_generated} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            invoice_generated: e.target.value
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Receiver</label>
                                    <input type="text" value={data.receiver} className="form-control"
                                        onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            receiver: e.target.value
                                        }))} />
                                </div>
                                <div className="form-group">
                                    <label>Comment</label>
                                    <textarea type="text" value={data.comment} className="form-control"
                                        style={{ height: "125px" }} onChange={(e) => setData((prev) => ({
                                            ...prev,
                                            comment: e.target.value
                                        }))} />
                                </div>
                                <div className="form-group checkbox-container">
                                    <div>
                                        <input value={data.insurance}
                                            defaultChecked={[data].some(i => i.insurance === true)}
                                            id="has-insurance"
                                            type="checkbox" onChange={(e) => setData((prev) => ({
                                                ...prev,
                                                insurance: e.target.checked
                                            }))} />
                                        <label htmlFor="has-insurance">Insurance</label>
                                    </div>
                                    <div>
                                        <input value={data.has_key} defaultChecked={[data].some(i => {
                                            return i.has_key === true
                                        })} id="has-key" type="checkbox"
                                            onChange={(e) => setData((prev) => ({
                                                ...prev,
                                                has_key: e.target.checked
                                            }))} />
                                        <label htmlFor="has-key">Key</label>
                                    </div>
                                    <div>
                                        <input value={data.is_sublot}
                                            defaultChecked={[data].some(i => i.is_sublot === true)} id="is-sublot"
                                            type="checkbox" onChange={(e) => setData((prev) => ({
                                                ...prev,
                                                is_sublot: e.target.checked
                                            }))} />
                                        <label htmlFor="is-sublot">Sublot</label>
                                    </div>
                                    <div>
                                        <input value={data.fine} defaultChecked={[data].some(i => i.fine === true)}
                                            id="fine" type="checkbox"
                                            onChange={(e) => setData((prev) => ({
                                                ...prev,
                                                fine: e.target.checked
                                            }))} />
                                        <label htmlFor="fine">Fine</label>
                                    </div>
                                    <div>
                                        <input value={data.ev_hybrid}
                                            defaultChecked={[data].some(i => i.ev_hybrid === true)} id="hybrid"
                                            type="checkbox" onChange={(e) => setData((prev) => ({
                                                ...prev,
                                                ev_hybrid: e.target.checked
                                            }))} />
                                        <label htmlFor="hybrid">EV hybrid</label>
                                    </div>
                                    <div>
                                        <input value={data.payed} defaultChecked={[data].some(i => i.payed === true)}
                                            id="payed" type="checkbox"
                                            onChange={(e) => setData((prev) => ({
                                                ...prev,
                                                payed: e.target.checked
                                            }))} />
                                        <label htmlFor="payed">Payed</label>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`col-md-12 ${actionType === "edit" ? 'd-flex justify-content-between' : ''}`}>
                                {actionType === "edit" && <Button type="submit" style={{ width: "40%" }}
                                    variant="danger" onClick={() => {
                                        setShowRemoveCarModal(true);
                                        hide(false);
                                    }}>Remove car</Button>}
                                <Button type="submit" style={{ width: actionType === "add" ? "100%" : "40%" }}
                                    variant="dark">{actionType === "add" ? "Add" : "Save"}</Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={showRemoveCarModal} onHide={() => setShowRemoveCarModal(false)}>
                <Modal.Header><h4>Do you want to remove this car?</h4></Modal.Header>
                <Modal.Body>
                    <div className={"modal-buttons"}>
                        <Button variant={"danger"} style={{ marginRight: "15px" }} onClick={handlers.deleteCar}>Yes</Button>
                        <Button value={"dark"} onClick={() => {
                            setShowRemoveCarModal(false);
                            hide(true);
                        }}>No</Button>
                    </div>
                </Modal.Body>
            </Modal></>
    )
}

export default EditCarsAdmin