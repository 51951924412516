import axios from 'axios';
import { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import image from '../Cars/icons.svg'

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [total, setTotal] = useState(0);
    const [pageChanged, setPageChanged] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [active, setActive] = useState({});
    const [showViewNotifications, setShowViewNotifications] = useState(false);

    useEffect(() => {
        axios(`/api/notifications?page=${pageChanged}&limit=10&keyword=${keyword}`)
            .then((res) => {
                const data = res.data.data;
                setNotifications([...data]);
                setTotal(res.data.total);
            })
            .catch((err) => {
                console.log(err)
            })
    }, [pageChanged, keyword]);

    return (
        <>
            <Table responsive className="responsive-data-table">
                <thead>
                    <tr>
                        <th style={{ width: "350px" }}>Title</th>
                        <th style={{ flex: "5" }}>Text</th>
                        <th style={{ width: "180px" }}>Date</th>
                        <th style={{ width: "120px" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        notifications.map((n) => {
                            return (
                                <tr key={n.id}>
                                    <td style={{ width: "350px" }} title={n.title}>{n.title.slice(0, 20)}{n.body.length > 20 && "..."}</td>
                                    <td style={{ flex: "5" }} title={n.body}>{n.body.slice(0, 35)}{n.body.length > 35 && "..."}</td>
                                    <th style={{ width: "180px" }}>{n.date}</th>
                                    <td style={{ width: "120px" }}>
                                        <img className="actions-icon" style={{ marginLeft: "60px" }} onClick={() => {
                                            setActive({...n});
                                            setShowViewNotifications(true);
                                        }} title="Check images" src={"https://usdelivery.ge/static/image/eye.svg"} alt="view" />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        notifications.length === 0 && (
                            <tr>
                                <td colSpan="8">
                                    <div className="empty-records"><p>Records not found</p></div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>

            <div className={"pagonation-content"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                    {'<<'}
                </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return
                                } else {
                                    return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                        setPageChanged(p + 1)
                                    }
                                    }>
                                    {p + 1}
                                </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                    {'>>'}
                </span>}
                </div>
                <p><strong>Total:</strong> {total}</p>
            </div>

            <Modal show={showViewNotifications} onHide={() => setShowViewNotifications(false)} centered size="lg">
                <Modal.Header>
                    <h3>{active.title}</h3>
                </Modal.Header>
                <Modal.Body>
                    {active.body}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='dark' onClick={() => setShowViewNotifications(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Notifications