import axios from "axios"
import { useEffect, useState } from "react"
import { Modal, Table, Button } from "react-bootstrap"
import './Cars.css'
import TableHeader from "../../Common/TableHeader/TableHeader"
import AddCarsModal from "../AddCarsModal"
import ViewCarsModal from "../ViewCarsModal"
import image from './icons.svg'

const Cars = () => {
    const [total, setTotal] = useState(0);
    const [pageChanged, setPageChanged] = useState(1);
    const [cars, setCars] = useState([]);
    const [showImages, setShowImages] = useState('');
    const [showViewModal, setShowViewModal] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [sort, setSort] = useState({ sort: "id", desc: "yes" });
    const [notification, setNotification] = useState({
        message: "",
        body: "",
        show: false
    })
    const [active, setActive] = useState({});
    const [addCarsModal, setAddCarsModal] = useState(false);
    const [formData, setFormData] = useState({
        vin: "", mark: "", model: "", year: "", color: "", title: "", auction: "", lot: "", is_sublot: false, has_key: false, insurance: false
    });
    const [editCarsModal, setEditCarsModal] = useState(false);
    const [showInvoices, setShowInvoices] = useState(false);

    useEffect(() => {
        axios(`/api/cars?limit=10&page=${pageChanged}&keyword=${keyword}&sort=${sort.sort}&desc=${sort.desc}`)
            .then((res) => {
                const data = res.data.data;
                setCars([...data]);
                setTotal(res.data.total)
            })
            .catch((err) => {
                setNotification({
                    message: "Server side error",
                    body: "Cars not loaded",
                    show: true
                })
            })
    }, [pageChanged, keyword]);

    const handlers = {
        async addNewCar(e) {
            e.preventDefault();
            try {
                const response = await axios.post('/api/cars', formData);
                setCars([{ ...formData }, ...cars]);
                setAddCarsModal(false);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.err.sqlMessage.slice(-17) === "'cars.vin_UNIQUE'" ? "Duplicate vin" : data.body,
                    show: true
                })
            }

            try{
                const res = await axios.post(`/api/users/debt/${formData.linked_user}`, {amount: formData.price})
            }catch (err){
                setNotification({
                    message: "Error",
                    body: "Debt not added",
                    show: true
                })
            }
        },
        showAddCarsModal() {
            setAddCarsModal(true);
            setFormData({
                vin: "", mark: "", price: "", model: "", year: "", document: "", comment: "", color: "", auction_city: "", state: "", title: "", auction: "", lot: "", is_sublot: false, has_key: false, insurance: false
            });
        },
        async editCar(e) {
            e.preventDefault();
            try {
                const response = await axios.put(`/api/cars/${active.vin}`, formData);
                setEditCarsModal(false);
                const data = cars.map((c) => {
                    if (c.vin === active.vin) {
                        return { ...c, receiver: formData.receiver, vin: formData.vin, mark: formData.mark, model: formData.model, year: formData.year, document: formData.document, comment: formData.comment, color: formData.color, auction_city: formData.auction_city, state: formData.state, title: formData.title, auction: formData.auction, lot: formData.lot, is_sublot: formData.is_sublot, has_key: formData.has_key, insurance: formData.insurance }
                    } else {
                        return { ...c }
                    }
                })
                setCars([...data]);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.body,
                    show: true
                })
            }
        },
        async deleteCar(e) {
            e.preventDefault();
            try {
                const response = await axios.detele(`/api/cars/${active.vin}`, formData);
                const data = cars.filter((c) => c.vin !== active.vin);
                setCars([...data])
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.body,
                    show: true
                })
            }
        }
    }

    return (
        <>
            <TableHeader linkedUsers={false} keyword={setKeyword} search={true} main={false} action={handlers.showAddCarsModal} />
            <Table responsive className="responsive-data-table">
                <thead>
                    <tr className="table-header">
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "year", desc: prev.desc === "yes" ? "no" : "yes" }))}>Year</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "mark", desc: prev.desc === "yes" ? "no" : "yes" }))}>Mark</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "model", desc: prev.desc === "yes" ? "no" : "yes" }))}>Model</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "vin", desc: prev.desc === "yes" ? "no" : "yes" }))}>VIN</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "lot", desc: prev.desc === "yes" ? "no" : "yes" }))}>Lot</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "auction", desc: prev.desc === "yes" ? "no" : "yes" }))}>Auction</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "auction_city", desc: prev.desc === "yes" ? "no" : "yes" }))}>City</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "title", desc: prev.desc === "yes" ? "no" : "yes" }))}>Title</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "year", desc: prev.desc === "yes" ? "no" : "yes" }))}>Container #</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "price", desc: prev.desc === "yes" ? "no" : "yes" }))}>Price</th>
                        <th className="table-header-items sortable" onClick={() => setSort((prev) => ({ sort: "receiver", desc: prev.desc === "yes" ? "no" : "yes" }))}>Receiver</th>
                        <th className="table-header-items"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        cars.map((car) => {
                            const arrivalDate = +new Date(car.arrival_date);
                            const dateNow = +new Date();
                            const dateResult = arrivalDate - dateNow;
                            return (
                                <tr>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.year}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.mark.replaceAll("_", " ")}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.model.replaceAll("_", " ")}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}><span>{car.vin.slice(0, -6)}</span><span style={{ color: "red", fontWeight: "bold" }}>{car.vin.slice(-6)}</span></td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.lot}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.auction}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.auction_city}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.title ? <img style={{ width: "20px" }} src="/static/icons/check.png" alt="Yes" /> : <img style={{ width: "20px" }} src="/static/icons/cross.png" alt="No" />}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.container_number}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.price}</td>
                                    <td onClick={() => {
                                        setShowViewModal(true);
                                        setActive({ ...car });
                                    }} className={`${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>{car.receiver}</td>
                                    <td style={{ width: "70px" }} className={`table-action-items ${car.payed ? "payed-item" : ''} ${(dateResult < 604800000 && car.payed === false && car.arrival_date != null) ? "unpaid-item" : ""}`}>
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} src={"/static/icons/invoice.png"} alt="view" onClick={() => {
                                            setShowInvoices(true);
                                            setActive({ ...car });
                                        }} />
                                        <img className="actions-icon" title="Check images" style={{ marginLeft: "0px" }} src={image} alt="view" onClick={() => {
                                            setActive({ ...car });
                                            setShowImages(true);
                                        }} />
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} src="https://usdelivery.ge/static/image/edit.svg" alt="edit" onClick={() => {
                                            setActive({ ...car });
                                            setFormData({
                                                vin: car.vin, user_uploaded_images: car.user_uploaded_images, mark: car.mark, receiver: car.receiver, model: car.model, year: car.year, document: car.document, comment: car.comment, color: car.color, auction_city: car.auction_city, state: car.state, title: car.title, auction: car.auction, lot: car.lot, is_sublot: car.is_sublot, has_key: car.has_key, insurance: car.insurance
                                            });
                                            setEditCarsModal(true)
                                        }} />

                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        cars.length === 0 && (
                            <tr>
                                <td colSpan="13">
                                    <div className="empty-records"><p>Records not found</p></div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <div className={"pagonation-content"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                    {'<<'}
                </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return
                                } else {
                                    return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                        setPageChanged(p + 1)
                                    }
                                    }>
                                    {p + 1}
                                </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                    {'>>'}
                </span>}
                </div>
                <p><strong>Total:</strong> {total}</p>
            </div>
            <Modal show={notification.show} onHide={() => {
                setNotification((prev) => ({ ...prev, show: false }))
            }} >
                <Modal.Header>
                    <h3>{notification.message}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h4>{notification.body}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {
                        setNotification((prev) => ({ ...prev, show: false }))
                    }}>Ok</Button>
                </Modal.Footer>
            </Modal>
            <AddCarsModal submitFormHandler={handlers.addNewCar} data={formData} setData={setFormData} actionType={"add"} hide={() => setAddCarsModal(false)} show={addCarsModal} />
            <AddCarsModal submitFormHandler={handlers.editCar} data={formData} setData={setFormData} actionType={"edit"} hide={() => setEditCarsModal(false)} show={editCarsModal} />
            <ViewCarsModal data={active} show={showViewModal} setShowModal={setShowViewModal} />
            <Modal show={showImages} onHide={() => {
                setShowImages(false)
            }} centered size="lg" >
                <Modal.Header><h3>Car images</h3></Modal.Header>
                <Modal.Body>
                    <h4>Auction images</h4>
                    {
                        active.auction_images && active.auction_images.map((i) => {
                            return <img className="car-images" src={i} alt="auction image" />
                        })
                    }
                    <h4 style={{ marginTop: "30px" }}>Port images</h4>
                    {
                        active.port_images && active.port_images.map((i) => {
                            return <img className="car-images" src={i} alt="auction image" />
                        })
                    }
                </Modal.Body>
            </Modal>
            <Modal show={showInvoices} onHide={() => {
                setShowInvoices(false)
            }} centered size="lg" >
                <Modal.Header><h3>Car invoices</h3></Modal.Header>
                <Modal.Body>
                    <h4 style={{marginBottom: "20px"}}>Original invoice</h4>
                    <a target={"_blank"} href={active.invoice_original}>{active.invoice_original}</a>
                    <h4 style={{ marginTop: "30px", marginBottom: "20px" }}>Generated invoice</h4>
                    <a target={"_blank"} href={active.invoice_original}>{active.invoice_generated}</a>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Cars