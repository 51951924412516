import { useEffect, useState } from "react"
import { Button, Modal, Table } from "react-bootstrap"
import AddCalculatorItemModal from "./AddCalculatorItemModal";
import axios from 'axios';

const Calculator = () => {
    const [items, setItems] = useState([]);
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [formData, setFormData] = useState({
        auction: "", state: "", port: "", price: "", fee: ""
    });
    const [showDeleteRecordModal, setShowDeleteRecordModal] = useState(false);
    const [type, setType] = useState("");
    const [tableActions, setTableActions] = useState(false);
    const [loader, setLoader] = useState(true);
    const [total, setTotal] = useState(0);
    const [pageChanged, setPageChanged] = useState(1);
    const [keyword, setKeyword] = useState("");

    const handlers = {
        addNewRecord(e) {
            e.preventDefault();
            axios.post('/api/calculator', formData)
                .then((res) => {
                    const id = res.data.id;
                    setItems((prev) => ([...prev, { id: id, ...formData }]));
                    setFormData({
                        auction: "", state: "", port: "", price: "", fee: ""
                    });
                    setShowAddItemModal(false);
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        editRecortHandler(e) {
            e.preventDefault();
            axios.put(`/api/calculator/${formData.id}`, formData)
                .then((res) => {
                    const data = items.map((i) => {
                        if (i.id === formData.id) {
                            return { ...formData }
                        } else {
                            return { ...i }
                        }
                    })
                    setItems((prev) => ([...data]));
                    setFormData({
                        auction: "", state: "", port: "", price: "", fee: ""
                    });
                    setShowAddItemModal(false);
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        deleteItem(e) {
            e.preventDefault();
            axios.delete(`/api/calculator/${formData.id}`)
                .then(() => {
                    const data = items.filter((i) => i.id !== formData.id);
                    setItems([...data]);
                    setShowDeleteRecordModal(false);
                    setTableActions(!tableActions);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        axios(`/api/calculator?limit=10&page=${pageChanged}&keyword=${keyword}`)
            .then((res) => {
                const data = res.data.data;
                setItems([...data]);
                setFormData({
                    auction: "", state: "", port: "", price: "", fee: ""
                });
                setLoader(false);
                setTotal(res.data.total)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [tableActions, keyword, pageChanged])

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
                <input type="search" style={{ maxWidth: "300px" }} className="form-control" placeholder="Search by Auction, state or Port" onKeyDown={(e) => {
                    if(e.keyCode === 13){
                        setKeyword(e.target.value);
                    }
                }} />
                <Button variant='dark' onClick={() => {
                    setShowAddItemModal(true);
                    setType("add");
                    setFormData({
                        auction: "", state: "", port: "", price: "", fee: ""
                    });
                }}>Add</Button>
            </div>
            <Table responsive className="responsive-data-table">
                <thead>
                    <tr>
                        <th>Auction</th>
                        <th>State</th>
                        <th>Port</th>
                        <th>Price</th>
                        <th>Fee</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((i) => {
                            return (
                                <tr key={i.id}>
                                    <td>{i.auction}</td>
                                    <td>{i.state}</td>
                                    <td>{i.port}</td>
                                    <td>${i.price}</td>
                                    <td>${i.fee}</td>
                                    <td className="table-action-items">
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} src="https://usdelivery.ge/static/image/edit.svg" alt="edit" onClick={() => {
                                            setFormData({
                                                auction: i.auction, state: i.state, port: i.port, price: i.price, fee: i.fee, id: i.id
                                            });
                                            setShowAddItemModal(true);
                                            setType('edit');
                                        }} />
                                        <img className="actions-icon" style={{ marginLeft: "10px" }} src="https://cdn-icons-png.flaticon.com/512/484/484662.png" alt="delete" onClick={() => {
                                            setFormData({
                                                auction: i.auction, state: i.state, port: i.port, price: i.price, fee: i.fee, id: i.id
                                            });
                                            setShowDeleteRecordModal(true)
                                        }} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        items.length === 0 && (
                            <tr>
                                <td colSpan="8">
                                    <div className="empty-records"><p>{loader ? "Loading..." : "Records not found"}</p></div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <div className={"pagonation-content"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                    {'<<'}
                </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return
                                } else {
                                    return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                        setPageChanged(p + 1)
                                    }
                                    }>
                                    {p + 1}
                                </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                    {'>>'}
                </span>}
                </div>
                <p><strong>Total:</strong> {total}</p>
            </div>
            <Modal show={showDeleteRecordModal} onHide={() => setShowDeleteRecordModal(false)} size="md">
                <Modal.Header>
                    <h3>Delete record</h3>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you really want to delete this record?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handlers.deleteItem}>Yes</Button>
                    <Button variant="dark" onClick={() => setShowDeleteRecordModal(false)}>No</Button>
                </Modal.Footer>
            </Modal>
            <AddCalculatorItemModal type={type} show={showAddItemModal} onSubmitHandler={type === "add" ? handlers.addNewRecord : handlers.editRecortHandler} hide={setShowAddItemModal} setData={setFormData} data={formData} />
        </>
    )
}

export default Calculator