import axios from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap"

const UploadImages = ({ show, setShow, vin, data, setData, cars, setCar }) => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [portImages, setPortImages] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [showUserImages, setShowUserImages] = useState(false);

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    const handleImagesChange = (event) => {
        setPortImages(event.target.files);
    };

    const handleFileUpload = () => {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('images', selectedFiles[i]);
        }
        formData.append('status', 'auction');
        formData.append('vin', vin);
        setLoading(true)


        // Adjust the URL to match your Express server route for handling file uploads
        axios.post('/api/cars/images', formData)
            .then((response) => {
                console.log('Upload successful:', response.data);
                alert("Images uploaded successfully");
                const images = response.data.data;
                setData((prev) => {
                    return { ...prev, auction_images: JSON.parse(images) }
                });
                const data = cars.map((c) => {
                    if (c.vin === vin) {
                        return { ...c, auction_images: JSON.parse(images) }
                    } else {
                        return { ...c }
                    }
                })
                setCar([...data])
                setLoading(false);
            })
            .catch((error) => {
                console.error('Upload failed:', error);
                setLoading(false);
                alert("Something went wrong");
            });
    };

    const uploadPortImages = () => {
        const formData = new FormData();
        for (let i = 0; i < portImages.length; i++) {
            formData.append('images', portImages[i]);
        }
        formData.append('status', 'port');
        formData.append('vin', vin);
        setLoading1(true)


        // Adjust the URL to match your Express server route for handling file uploads
        axios.post('/api/cars/images', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                console.log('Upload successful:', response.data);
                alert("Images uploaded successfully");
                const images = response.data.data;
                setData((prev) => {
                    return { ...prev, port_images: JSON.parse(images) }
                });
                const data = cars.map((c) => {
                    if (c.vin === vin) {
                        return { ...c, port_images: JSON.parse(images) }
                    } else {
                        return { ...c }
                    }
                })
                setCar([...data]);
                setLoading1(false);
            })
            .catch((error) => {
                console.error('Upload failed:', error);
                alert("Something went wrong")
                setLoading1(false)
            });
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} centered size="md">
            <Modal.Header>
                <h3>Upload images</h3>
            </Modal.Header>
            <Modal.Body>
                <h4>User uploaded images</h4>
                <div style={{display: "flex", alignItems: "center"}}><input type={"checkbox"} onChange={() => setShowUserImages(!showUserImages)} id={"show-images"}/><label htmlFor={"show-images"}>Show images</label></div>
                {showUserImages && <div>
                    {
                        data.user_uploaded_images && data.user_uploaded_images.map((i, k) => {
                            return <img className="car-images" key={k * 10} src={i} alt="auction image"/>
                        })
                    }
                </div>}
                <h4>Auction images</h4>
                <div>
                    <input type="file" multiple style={{width: "250px"}} onChange={handleFileChange} />
                    <button onClick={handleFileUpload} disabled={loading ? true : false}>Upload</button>
                    {loading && <span style={{marginLeft: "8px"}}>Uploading...</span>}
                </div>
                {
                    data.auction_images && data.auction_images.map((i, k) => {
                        return <img className="car-images" key={k * 1000} src={i} alt="auction image" />
                    })
                }

                <h4 style={{ marginTop: "45px" }}>Port images</h4>
                <div>
                    <input type="file" multiple style={{width: "250px"}} onChange={handleImagesChange} />
                    {loading && <span>Uploading...</span>}
                    <button onClick={uploadPortImages} disabled={loading ? true : false}>Upload</button>
                    {loading1 && <span style={{marginLeft: "8px"}}>Uploading...</span>}
                </div>
                {
                    data.port_images && data.port_images.map((i, k) => {
                        return <img className="car-images" key={k * 100000} src={i} alt="auction image" />
                    })
                }
            </Modal.Body>
        </Modal>
    )
}

export default UploadImages