import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './Users/Container.css'
import pin from './pin.svg';
import L from 'leaflet';
import { Button } from 'react-bootstrap'

const Container = ({ latitude, longitude }) => {
    const [position, setPosition] = useState([42.15, 41.67]);
    const [containerNumber, setContainerNumber] = useState('');
    const [containerInfo, setContainerInfo] = useState({

    })
    const [showDetails, setShowDetails] = useState(false);
    const [showEvents, setShowEvents] = useState(false);
    const [locationCode, setLocationCode] = useState("");
    const searchByContainer = async (e) => {
        if (e.keyCode === 13) {
            try {
                const response = await axios.get(`/api/container/${containerNumber}`);
                const data = response.data.data;
                // setPosition([...data.pin]);
                setContainerInfo({ ...data });
                setShowDetails(true);
            } catch (err) {
                alert("Something went wrong")
            }
        }
    }

    const search = async () => {
        try {
            const response = await axios.get(`/api/container/${containerNumber}`);
            const data = response.data.data;
            // setPosition([...data.pin]);
            setContainerInfo({ ...data });
            setShowDetails(true);
        } catch (err) {
            alert("Something went wrong")
        }
    }
    useEffect(() => {
        const container = new URLSearchParams(window.location.search).get("containerNumber");
        if (container != "" && container != null && typeof container != "undefined") {
            axios.get(`/api/container/${container}`)
                .then((response) => {
                    const data = response.data.data;
                    // setPosition([...data.pin]);
                    setContainerInfo({ ...data });
                    setShowDetails(true);
                })
                .catch((err) => {
                    alert("Something went wrong")
                })
        }
    }, [])

    const customIcon = L.icon({
        iconUrl: pin, // URL to your custom icon image
        iconSize: [32, 32], // Size of the icon image
        iconAnchor: [16, 32], // Anchor point of the icon
        popupAnchor: [0, -32], // Anchor point of the popup
    });

    return (
        <div id='map'>
            {
                showDetails && <>
                    <h3 style={{ color: "white" }}>Container details</h3>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='container-detail-item'><strong>Destination: </strong> <p className='container-detail-value'>{containerInfo.destination.country}, {containerInfo.destination.city}</p></div>
                            <div className='container-detail-item'><strong>Country code: </strong> <p className='container-detail-value'>{containerInfo.destination.country_code}</p></div>
                            <div className='container-detail-item'><strong>Terminal: </strong> <p className='container-detail-value'>{containerInfo.destination.terminal}</p></div>
                            <div className='container-detail-item'><strong>Country of exit: </strong> <p className='container-detail-value'>{containerInfo.origin.country}</p></div>
                            <div className='container-detail-item'><strong>City of exit: </strong> <p className='container-detail-value'>{containerInfo.origin.city}</p></div>
                            <div className='container-detail-item'><strong>Terminal of exit: </strong> <p className='container-detail-value'>{containerInfo.origin.terminal}</p></div>
                            <div className='container-detail-item'><strong>State of exit: </strong> <p className='container-detail-value'>{containerInfo.origin.state}</p></div>
                        </div>
                        <div className='col-md-6'>
                            <div className='container-detail-item'><strong>Container number: </strong> <p className='container-detail-value'>{containerInfo.containers[0].container_num}</p></div>
                            <div className='container-detail-item'><strong>Container size: </strong> <p className='container-detail-value'>{containerInfo.containers[0].container_size}</p></div>
                            <div className='container-detail-item'><strong>Container type: </strong> <p className='container-detail-value'>{containerInfo.containers[0].container_type}</p></div>
                            <div className='container-detail-item'><strong>ETA final date: </strong> <p className='container-detail-value'>{new Date(containerInfo.containers[0].eta_final_delivery).getFullYear()}/{new Date(containerInfo.containers[0].eta_final_delivery).getMonth() + 1}/{new Date(containerInfo.containers[0].eta_final_delivery).getDate()}</p></div>
                            <div className='container-detail-item'><strong>ISO code: </strong> <p className='container-detail-value'>{containerInfo.containers[0].iso_code}</p></div>
                            <div className='container-detail-item'><strong>Operator: </strong> <p className='container-detail-value'>{containerInfo.containers[0].operator}</p></div>
                            <div className='container-detail-item'><strong>Status: </strong> <p className='container-detail-value'>{containerInfo.containers[0].status}</p></div>
                        </div>
                    </div>
                    <h3 style={{ color: "white", marginTop: "30px" }}>Locations</h3>
                    <div className='locations-history-container'>
                        <div className='locations-history-items'>
                            {
                                containerInfo.containers[0].locations.map((location) => {
                                    return (
                                        <div className='history-item' key={location.get_site}>
                                            <div className='locations-history-item' >
                                                <strong>Country:</strong><p className='location-details-value'>{location.country}</p>
                                            </div>
                                            <div className='locations-history-item' >
                                                <strong>City:</strong><p className='location-details-value'>{location.city}</p>
                                            </div>
                                            <div className='locations-history-item' >
                                                <strong>Country code:</strong><p className='location-details-value'>{location.country_code}</p>
                                            </div>
                                            <div className='locations-history-item' >
                                                <strong>Location code:</strong><p className='location-details-value'>{location.location_code}</p>
                                            </div>
                                            <div className='locations-history-item' >
                                                <strong>Terminal:</strong><p className='location-details-value'>{location.terminal}</p>
                                            </div>
                                            {locationCode !== location.location_code && <div className='show-events-button-container'><Button variant='dark' onClick={() => {
                                                setLocationCode(location.location_code);
                                                setShowEvents(true)
                                            }} >Show Events</Button></div>}
                                            {locationCode === location.location_code && <div className='show-events-button-container'><Button variant='dark' onClick={() => {
                                                setLocationCode("");
                                                setShowEvents(false)
                                            }} >Hide Events</Button></div>}
                                            {
                                                (showEvents && locationCode === location.location_code) && location.events.map((event) => {
                                                    return (
                                                        <div className='events-divider'>
                                                            <div className='locations-history-item' >
                                                                <strong>Actfor:</strong><p className='location-details-value'>{event.actfor}</p>
                                                            </div>
                                                            <div className='locations-history-item' >
                                                                <strong>Activity:</strong><p className='location-details-value'>{event.activity}</p>
                                                            </div>
                                                            <div className='locations-history-item' >
                                                                <strong>Actual time:</strong><p className='location-details-value'>{event.actual_time}</p>
                                                            </div>
                                                            <div className='locations-history-item' >
                                                                <strong>Event time:</strong><p className='location-details-value'>{event.event_time}</p>
                                                            </div>
                                                            <div className='locations-history-item' >
                                                                <strong>Event time type:</strong><p className='location-details-value'>{event.event_time_type}</p>
                                                            </div>
                                                            <div className='locations-history-item' >
                                                                <strong>Terminal:</strong><p className='location-details-value'>{event.terminal}</p>
                                                            </div>
                                                            <div className='locations-history-item' >
                                                                <strong>Cancelled:</strong><p className='location-details-value'>{event.canceled ? "Yes" : "No"}</p>
                                                            </div>
                                                            <div className='locations-history-item' >
                                                                <strong>Current:</strong><p className='location-details-value'>{event.current ? "Yes" : "No"}</p>
                                                            </div>
                                                            <div className='locations-history-item' >
                                                                <strong>Stempty:</strong><p className='location-details-value'>{event.stempty ? "Yes" : "No"}</p>
                                                            </div>
                                                            <div className='locations-history-item' >
                                                                <strong>Type:</strong><p className='location-details-value'>{event.type}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            }
            <div className='map-container'>
                <iframe id="container-tracking" className='tracking-map' style={{height:"1100px"}} src="https://www.vsbrothers.com/public/tracking" width="100%" height="680px" frameborder="0" align="middle" scrolling="No" allowfullscreen="Yes"></iframe>
            </div>
        </div>
    );

}

export default Container