import { createSlice } from "@reduxjs/toolkit";

const initialUsers = {
    isLoggedIn: null,
    username: "",
    name: "",
    surname: "",
    email: "",
    role: "",
    initials: "",
    userId: ""
}

const user = createSlice({
    name: "users",
    initialState: initialUsers,
    reducers: {
        changeIsLoggedIn(state, action){
            state.isLoggedIn = action.payload
        },
        setLoggedInHandler(state, action){
            state.role = action.payload;
        },
    }
})

export const usersActions = user.actions;

export default user.reducer