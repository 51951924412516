import axios from 'axios';
import { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import image from '../Cars/icons.svg'

const NotificationsAdmin = () => {
    const [notifications, setNotifications] = useState([]);
    const [total, setTotal] = useState(0);
    const [pageChanged, setPageChanged] = useState(1);
    const [showAddNotifications, setShowAddNotifications] = useState(false);
    const [formData, setFormData] = useState({
        title: "", body: "", active: false
    })
    const [keyword, setKeyword] = useState("");
    const [notification, setNotification] = useState({
        message: "",
        body: "",
        show: false
    });
    const [active, setActive] = useState({});
    const [deleteNotificationModal, setDeleteNotificationModal] = useState(false);
    const [action, setAction] = useState("");

    useEffect(() => {
        axios(`/api/notifications?page=${pageChanged}&limit=10&keyword=${keyword}`)
            .then((res) => {
                const data = res.data.data;
                setNotifications([...data]);
                setTotal(res.data.total);
            })
            .catch((err) => {
                console.log(err)
            })
    }, [pageChanged, keyword]);

    const handlers = {
        async addNewNotification(e) {
            e.preventDefault();
            const date = new Date();
            const current = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            try {
                const response = await axios.post(`/api/notifications`, {...formData, current: current});
                setNotifications([{ ...formData, date: current }, ...notifications]);
                setShowAddNotifications(false);
                setFormData({ title: "", body: "", active: false })
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.message,
                    show: true
                })
            }
        },
        async editNotification(e) {
            e.preventDefault();
            try {
                const response = await axios.put(`/api/notifications/${active.id}`, formData);
                const data = notifications.map((n) => {
                    if (n.id === active.id) {
                        return { ...n, active: formData.active, title: formData.title, body: formData.body }
                    } else {
                        return { ...n }
                    }
                })
                setNotifications([...data]);
                setShowAddNotifications(false);
                setFormData({ title: "", body: "", active: false })
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.message,
                    show: true
                })
            }
        },
        async deleteNotification() {
            try {
                const response = await axios.delete(`/api/notifications/${active.id}`);
                const data = notifications.filter((n) => n.id !== active.id);
                setNotifications([...data]);
                setDeleteNotificationModal(false);
            } catch (err) {
                const data = err.response.data;
                setNotification({
                    message: data.description,
                    body: data.message,
                    show: true
                })
            }
        }
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "30px" }}>
                <Button onClick={() => {
                    setShowAddNotifications(true);
                    setFormData({
                        name: "", surname: "", email: "", phone: ""
                    });
                    setAction("add");
                }} variant='dark'>Add new</Button>
            </div>
            <Table responsive className="responsive-data-table">
                <thead>
                    <tr>
                        <th style={{ width: "350px" }}>Title</th>
                        <th style={{ flex: "5" }}>Text</th>
                        <th style={{ width: "180px" }}>date</th>
                        <th style={{ width: "120px" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        notifications.map((n) => {
                            return (
                                <tr key={n.id}>
                                    <td style={{ width: "350px" }} title={n.title}>{n.title.slice(0, 20)}{n.body.length > 20 && "..."}</td>
                                    <td style={{ flex: "5" }} title={n.body}>{n.body.slice(0, 35)}{n.body.length > 35 && "..."}</td>
                                    <th style={{ width: "180px" }}>{n.date}</th>
                                    <td style={{ width: "120px" }}>
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} onClick={() => {
                                            setActive({...n});
                                            setDeleteNotificationModal(true);
                                        }} title="Check images" src='https://cdn-icons-png.flaticon.com/512/484/484662.png' alt="delete" />
                                        <img className="actions-icon" style={{ marginLeft: "0px" }} src="https://usdelivery.ge/static/image/edit.svg" onClick={() => {
                                            setFormData({title: n.title, body: n.body, active: n.active});
                                            setShowAddNotifications(true);
                                            setActive({...n});
                                            setAction("edit");
                                        }} alt="edit" />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        notifications.length === 0 && (
                            <tr>
                                <td colSpan="8">
                                    <div className="empty-records"><p>Records not found</p></div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>

            <div className={"pagonation-content"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                    {'<<'}
                </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return
                                } else {
                                    return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                        setPageChanged(p + 1)
                                    }
                                    }>
                                    {p + 1}
                                </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                    {'>>'}
                </span>}
                </div>
                <p><strong>Total:</strong> {total}</p>
            </div>

            <Modal show={showAddNotifications} onHide={() => setShowAddNotifications(false)} centered size="dm">
                <Modal.Header>
                    <h3>Add new notification</h3>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => {
                        if(action === "add"){
                            handlers.addNewNotification(e)
                        }else{
                            handlers.editNotification(e)
                        }
                    }}>
                        <div className="form-group">
                            <label >Title</label>
                            <input type="text" required value={formData.title} onChange={(e) => setFormData((prev) => ({ ...prev, title: e.target.value }))} className="form-control" placeholder="Enter title" />
                        </div>
                        <div className="form-group">
                            <label >Body</label>
                            <textarea type="text" rows={"7"} required value={formData.body} onChange={(e) => setFormData((prev) => ({ ...prev, body: e.target.value }))} className="form-control" placeholder="Enter body" />
                        </div>
                        <div className="form-group" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <input type="checkbox" defaultChecked={[formData].some(i => i.active === true)} id='active' value={formData.active} onChange={(e) => setFormData((prev) => ({ ...prev, active: e.target.checked }))} />
                            <label htmlFor='active' style={{ marginLeft: "5px" }}>active</label>
                        </div>
                        <Button type='submit' style={{ width: "100%" }} variant='dark'>{action !== "edit" ? "Add" : "Save"}</Button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={notification.show} onHide={() => {
                setNotification((prev) => ({ ...prev, show: false }))
            }} >
                <Modal.Header>
                    <h3>{notification.message}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h4>{notification.body}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {
                        setNotification((prev) => ({ ...prev, show: false }))
                    }}>Ok</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteNotificationModal} onHide={() => setDeleteNotificationModal(false)} size='md'>
                <Modal.Header>
                    <h3>Detele notification</h3>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure that you want to detele this notification?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={handlers.deleteNotification}>Yes</Button>
                    <Button variant='info' onClick={() => setDeleteNotificationModal(false)} >Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default NotificationsAdmin