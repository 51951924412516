import React from "react";
import { pushRotate as Menu } from "react-burger-menu";
import "./Sidebar.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import axios from "axios";


const Sidebar = ({ role, isOpen, setIsOpen }) => {

    const handleMenuStateChange = (state) => {
        setIsOpen(state.isOpen);
    };

    const logout = async () => {
        try {
            const response = await axios.post('/api/users/logout');
            window.location.href = "/";
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            {role === "admin" && <Menu
                isOpen={isOpen}
                onStateChange={handleMenuStateChange}
                right
                width={250} // Adjust the width as per your requirements
            >
                <Link className="menu-item" to="/">
                    Cars
                </Link>
                <Link className="menu-item" to="/calculator">
                    Calculator
                </Link>
                <Link className="menu-item" to="/users">
                    Users
                </Link>
                <Link className="menu-item" to="/notifications">
                    Notifications
                </Link>
                <Link className="menu-item" to="/invoices">
                    Invoices
                </Link>
                <Link className="menu-item" to="/links">
                    Links
                </Link>
                <Link className="menu-item" to="/container-tracking">
                    Search container
                </Link>
                <Button variant="dark" onClick={logout}>Log out</Button>
            </Menu>}
            {(role === "user" && (
                <Menu
                    isOpen={isOpen}
                    onStateChange={handleMenuStateChange}
                    right
                    width={250}
                >
                <Link className="menu-item" to="/">
                    Cars
                </Link>
                <Link className="menu-item" to="/calculator">
                    Calculator
                </Link>
                <Link className="menu-item" to="/users">
                    Users
                </Link>
                <Link className="menu-item" to="/notifications">
                    Notifications
                </Link>
                <Link className="menu-item" to="/invoices">
                    Invoices
                </Link>
                <Link className="menu-item" to="/container-tracking">
                    Search container
                </Link>
                    <Button variant="dark" onClick={logout}>Log out</Button>
                </Menu>
            ))}
        </div>
    );
};

export default Sidebar;